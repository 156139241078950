<template>
    <div>
        <v-dialog
            @input="$emit('dialogChanged', $event)"
            :value="dialogEdit"
            width="800"
            scrollable
        >
            <v-card class="card-default">
                <v-card-title class="text-h5 font-weight-bold">
                    {{ restaurant.name }} bearbeiten
                </v-card-title>
                <v-card-subtitle>
                    Editiere ein bestehendes Restaurant
                </v-card-subtitle>
                <v-divider></v-divider>
                <v-card-text>
                    <v-form v-model="valid" @submit.prevent="editRestaurant()">

                        <div class="pt-8">
                            <v-row>
                                <v-col cols="12">
                                    <v-card class="mx-auto card-default">
                                        <v-img
                                            height="200"
                                            dark
                                            :src="generateCover()"
                                            class="white--text d-flex align-center px-16"
                                        >
                                            <v-file-input
                                                v-model="newRestaurantCover"
                                                :rules="imageRules"
                                                accept="image/png, image/jpeg, image/svg"
                                                outlined
                                                class="text-center glassy mx-16 px-16 py-6 shadow--lighter" style="border-radius: 8px"
                                                placeholder="Wähle ein Profilbild"
                                                prepend-icon="mdi-camera"
                                                label="Profilbild"
                                                show-size
                                                persistent-hint
                                                hint="Folgende Bilddateien sind erlaubt: jpg, png, svg. Die Bildgröße darf nicht 5MB überschreiten."
                                            ></v-file-input>
                                        </v-img>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="newRestaurantData.name"
                                        outlined
                                        :rules="required"
                                        id="name"
                                        label="Name des Lokals"
                                        placeholder="Remy’s Ratatouille Adventure"
                                        name="name"
                                        required
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="newRestaurantData.googleApi"
                                        outlined
                                        id="googleApi"
                                        label="Google Api"
                                        placeholder="XxYXU6w6FXxYXcRpteXxYX"
                                        name="googleApi"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="8">
                                    <v-text-field
                                        v-model="newRestaurantData.street"
                                        outlined
                                        id="street"
                                        label="Straße"
                                        name="street"
                                        required
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="4">
                                    <v-text-field
                                        v-model="newRestaurantData.housenumber"
                                        outlined
                                        id="housenumber"
                                        label="Hausnummer"
                                        name="housenumber"
                                        required
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="newRestaurantData.postalcode"
                                        outlined
                                        id="postalcode"
                                        label="PLZ"
                                        name="postalcode"
                                        required
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="newRestaurantData.city"
                                        outlined
                                        id="city"
                                        label="Stadt"
                                        placeholder="Bielefeld"
                                        name="city"
                                        required
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </div>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        elevation="0"
                        @click="$emit('dialogChanged', false)"
                    >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        color="primary"
                        :disabled="loading || !valid"
                        @click="editRestaurant()"
                    >
                        Speichern
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "EditRestaurant",
    props: ['restaurant', 'dialogEdit'],
    methods: {
        editRestaurant() {
            this.loading = true;
            this.updateRestaurantImage()
            axios.put(`/restaurant/${this.restaurant.id}`, this.newRestaurantData)
                .then(() => {
                    this.$toast.success('Toll! Dein Lokal wurde aktualisiert');
                })
                .catch((e) => {
                    console.error(e);
                    this.$toast.error('Etwas ist schiefgelaufen. Versuche es noch einmal.');
                })
                .finally(() => {
                    this.loading = false;
                    this.$emit('updateRestaurants');
                    this.$emit('dialogChanged', false);
                })
            return true;
        },
        async updateRestaurantImage() {
            if (this.newRestaurantCover) {
                this.loading = true;
                const formData = new FormData();
                formData.append('file', this.newRestaurantCover);
                axios.post(`/restaurant/${this.restaurant.id}/image`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
                    .then(() => {
                        this.$toast.success('🎉 Erfolgreich gespeichert');
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast.error('Das Profilbild konnte nicht aktualisiert werden.');
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            }
        },
        generateCover() {
            if (this.restaurant.coverUrl) {
                return process.env.VUE_APP_BACKEND_API_URL + this.restaurant.coverUrl;
            } else return process.env.VUE_APP_BACKEND_API_URL + '/assets/placeholder_cover.jpg';
        },
        mappingRestaurantData() {
            this.newRestaurantData.name = this.restaurant.name
            this.newRestaurantData.googleApi = this.restaurant.googleApi
            this.newRestaurantData.cover = this.restaurant.cover
            this.newRestaurantData.street = this.restaurant.street
            this.newRestaurantData.housenumber = this.restaurant.housenumber
            this.newRestaurantData.postalcode = this.restaurant.postalcode
            this.newRestaurantData.city = this.restaurant.city

        }
    },
    mounted() {
        this.mappingRestaurantData();
    },
    data() {
        return {
            loading: false,
            valid: false,
            newRestaurantData: {
                name: null,
                googleApi: null,
                cover: null,
                street: null,
                housenumber: null,
                postalcode: null,
                city: null,
            },
            newRestaurantCover: null,

            imageRules: [
                value => !value || value.size < 5000000 || 'Die Größe des Avatars sollte weniger als 5 MB betragen!',
            ],
            required: [
                v => !!v || 'Dies ist ein Pflichtfeld',
            ],
        }
    }
}
</script>

<style scoped>

</style>