var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"value":_vm.dialogCreate,"width":"800","scrollable":""},on:{"input":function($event){return _vm.$emit('dialogChanged', $event)}}},[_c('v-card',{staticClass:"card-default"},[_c('v-card-title',{staticClass:"text-h5 font-weight-bold"},[_vm._v(" Worauf hast Du heute Hunger? ")]),_c('v-card-subtitle',[_vm._v(" Wähle das passendes Restaurant aus und... lass es dir schmecken! ")]),_c('v-divider'),_c('v-card-text',[_c('v-form',{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('div',{staticClass:"pt-8"},[_c('v-item-group',{attrs:{"mandatory":""},model:{value:(_vm.newEventData.restaurant),callback:function ($$v) {_vm.$set(_vm.newEventData, "restaurant", $$v)},expression:"newEventData.restaurant"}},[_c('v-row',_vm._l((_vm.restaurants),function(restaurant){return _c('v-col',{key:restaurant.id,attrs:{"cols":"6"}},[_c('v-item',{staticClass:"element-clickable",attrs:{"value":restaurant.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var active = ref.active;
var toggle = ref.toggle;
return [_c('v-img',{staticClass:"pa-2",staticStyle:{"border-radius":"4px"},attrs:{"src":_vm.generateRestaurantCover(restaurant),"height":"150","dark":""},on:{"click":toggle}},[_c('v-btn',{attrs:{"icon":"","dark":""}},[_c('v-icon',[_vm._v(" "+_vm._s(active ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline')+" ")])],1),_c('v-card-title',{staticClass:"absolute-details text-h6 font-weight-bold"},[_vm._v(" "+_vm._s(restaurant.name)+" ")])],1)]}}],null,true)})],1)}),1)],1)],1),_c('div',{staticClass:"d-flex align-center mt-6 mb-4"},[_c('v-divider'),_c('span',{staticClass:"mx-2 grey--text text-overline"},[_vm._v("zusätzliche Informationen")]),_c('v-divider')],1),_c('div',{staticClass:"text-center"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-menu',{ref:"collectTime",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.newEventData.collectTime,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){return _vm.$set(_vm.newEventData, "collectTime", $event)},"update:return-value":function($event){return _vm.$set(_vm.newEventData, "collectTime", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","label":"Abholungs- / Reservierungszeit *","prepend-icon":"mdi-clock-time-two-outline","hint":"Öffnungszeiten heute: 11:00 - 19:30","persistent-hint":"","readonly":"","rules":[_vm.rules.required]},model:{value:(_vm.newEventData.collectTime),callback:function ($$v) {_vm.$set(_vm.newEventData, "collectTime", $$v)},expression:"newEventData.collectTime"}},'v-text-field',attrs,false),on))]}}])},[_c('v-time-picker',{attrs:{"full-width":"","format":"24hr","allowed-minutes":_vm.allowedStep,"scrollable":""},on:{"click:minute":function($event){return _vm.$refs.collectTime.save(_vm.newEventData.collectTime)}},model:{value:(_vm.newEventData.collectTime),callback:function ($$v) {_vm.$set(_vm.newEventData, "collectTime", $$v)},expression:"newEventData.collectTime"}})],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-select',{attrs:{"items":_vm.allUsers,"label":"Erstellt von","item-text":"firstname","item-value":"id","readonly":"","outlined":""},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"pl-1"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"secondary"}},[_c('v-img',{attrs:{"src":_vm.avatarSrc}})],1),_vm._v(" "+_vm._s(item.firstname)+" "+_vm._s(item.lastname)+" ")],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"pl-1"},[_c('v-avatar',{staticClass:"mr-2",attrs:{"color":"pink"}},[_vm._v("AV")]),_vm._v(" "+_vm._s(item.firstname)+" "+_vm._s(item.lastname)+" ")],1)]}}]),model:{value:(_vm.newEventData.createdBy),callback:function ($$v) {_vm.$set(_vm.newEventData, "createdBy", $$v)},expression:"newEventData.createdBy"}},[_c('template',{slot:"append"},[_c('span',{staticClass:"text-body-2 success--text"},[_vm._v("+10 Punkten")])])],2)],1)],1)],1)])],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"elevation":"0"},on:{"click":function($event){return _vm.$emit('dialogChanged', false)}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.loading || !_vm.valid},on:{"click":function($event){return _vm.createEvent()}}},[_vm._v(" Speichern ")])],1)],1)],1),_c('v-snackbar')],1)}
var staticRenderFns = []

export { render, staticRenderFns }