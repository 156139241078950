<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-card class="card-transparent">
                    <v-card-title class="text-h5 font-weight-bold">Admin Dashboard</v-card-title>
                    <v-card-subtitle>Finde und verwalte jeden Benutzer der Hunger App.
                    </v-card-subtitle>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-tabs vertical class="tabs-default">
                    <v-tab class="tab-default">
                        <v-icon left>
                            mdi-account-group
                        </v-icon>
                        Benutzer
                    </v-tab>
                    <v-tab class="tab-default">
                        <v-icon left>
                            mdi-domain
                        </v-icon>
                        Firmen
                    </v-tab>

                    <v-tab-item>
                        <AdminUserTable></AdminUserTable>
                    </v-tab-item>
                    <v-tab-item>
                        <AdminCompaniesTable></AdminCompaniesTable>
                    </v-tab-item>
                </v-tabs>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import AdminUserTable from "../../components/admin/AdminUserTable.vue";
import AdminCompaniesTable from "../../components/admin/AdminCompaniesTable.vue";

export default {
    name: "AdminDashboard",
    components: {AdminCompaniesTable, AdminUserTable}
}
</script>

<style scoped>

</style>