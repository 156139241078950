<template>
    <v-dialog
        @input="$emit('dialogChanged', $event)"
        :value="dialogClose"
        width="800"
        scrollable
    >
        <v-card class="card-default">
            <v-card-title class="text-h5 font-weight-bold">
                Dieses Event wird abgeschlossen.
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="py-6">
                Dieser Schritt ist für Erstellung von Bewirtungsaufwendungen notwendig, er kann jedoch nicht rückgängig gemacht werden.
            </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    @click="$emit('dialogChanged', false)"
                >
                    Abbrechen
                </v-btn>

                <v-btn
                    color="primary"
                    dark
                    :loading="loading"
                    @click="closeEvent">Abschließen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import axios from "axios";

export default {
    name: "CloseEvent",
    props: ['event', 'dialogClose'],
    data() {
        return {
            dialog: false,
            loading: false,
        }
    },
    methods: {
        closeEvent() {
            this.loading = true;
            axios.get(`/event/close/${this.event.id}`)
                .then(() => {
                    this.$toast.success('Event abgeschlossen! Danke fürs mitmachen');
                })
                .catch((e) => {
                    console.error(e);
                    this.$toast.error('Das Event konnte nicht abgeschlossen werden. Bitte kontaktiere den Administrator');
                })
                .finally(() => {
                    this.$emit('dialogChanged', false)
                    this.loading = true;
                })
        }
    }
}
</script>
