<template>
    <v-form @submit="createNewGroup">
        <v-text-field
            filled
            rounded
            dense
            hide-details
            v-model="newGroup.title"
            placeholder="Fang an zu tippen..."
            append-outer-icon="mdi-plus"
            @click:append-outer="createNewGroup"
        >
        </v-text-field>
    </v-form>
</template>

<script>
import axios from "axios";

export default {
    name: "NewGroup",
    data() {
        return {
            newGroup: {
                title: null,
            }
        }
    },
    methods: {
        createNewGroup() {
            axios.post('/group', this.newGroup)
                .then(() => {
                    this.$toast.success('Prima! 🍱 Danke für die Erstellung einer neuen Gruppe');
                })
                .catch((e) => {
                    console.error(e);
                    this.$toast.error('Beim Anlegen der Gruppe ist etwas schiefgelaufen.');
                })
                .finally(() => {
                    this.newGroup.title = null;
                    this.$emit('fetchAllGroups');
                })
        }

    }
}
</script>

<style scoped>

</style>