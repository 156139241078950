import { render, staticRenderFns } from "./MenuList.vue?vue&type=template&id=25ea8a18&scoped=true&"
import script from "./MenuList.vue?vue&type=script&lang=js&"
export * from "./MenuList.vue?vue&type=script&lang=js&"
import style0 from "./MenuList.vue?vue&type=style&index=0&id=25ea8a18&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25ea8a18",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCol } from 'vuetify/lib/components/VGrid';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSkeletonLoader } from 'vuetify/lib/components/VSkeletonLoader';
import { VTextField } from 'vuetify/lib/components/VTextField';
installComponents(component, {VCol,VItemGroup,VRow,VSkeletonLoader,VTextField})
