<template>
    <v-data-table
        :headers="headers"
        :items="allCompanies"
        item-key="id"
        class="elevation-1"
        :items-per-page="20"
    >
        <template v-slot:item.colorHex="{item}">
            <div class="d-flex align-center">
                <v-badge
                    :color="'#' + item.colorHex"
                    inline
                    class="mr-1"
                ></v-badge>
                <span :style="`color: #`+item.colorHex">#{{ item.colorHex }}</span>
            </div>
        </template>
        <template slot="body.append">
            <div>
                <v-container>
                    <v-form @submit="createNewCompany">
                        <v-text-field
                            filled
                            rounded
                            outlined
                            dense
                            :rules=required
                            v-model="newCompany.name"
                            :background-color="newCompany.colorHex+'15'"
                            placeholder="Fang an zu tippen..."
                            :color="newCompany.colorHex"
                            append-outer-icon="mdi-plus"
                            @click:append-outer="createNewCompany"
                        >
                        </v-text-field>

                        <v-color-picker
                            show-swatches
                            hide-canvas
                            hide-sliders
                            hide-mode-switch
                            hide-inputs
                            :swatches="swatches"
                            v-model="newCompany.colorHex"
                        ></v-color-picker>
                    </v-form>
                </v-container>
            </div>
        </template>
    </v-data-table>
</template>

<script>
import axios from "axios";

export default {
    name: "AdminCompaniesTable",
    methods: {
        fetchAllCompanies() {
            this.loadingCompanies = true;
            axios.get(`/company`)
                .then((response) => {
                    this.allCompanies = response.data;
                })
                .catch((e) => {
                    console.error(e);
                    this.$toast.error('Firmen konnten nicht geladen werden');
                })
                .finally(() => {
                    this.loadingUsers = false
                })
        },
        createNewCompany() {
            this.newCompany.colorHex = this.newCompany.colorHex.substring(1);
            axios.post('/company', this.newCompany)
                .then(() => {
                    this.$toast.success('Toll! Deine neue Firma ist da!');
                })
                .catch((e) => {
                    console.error(e);
                    this.$toast.error('Etwas ist schiefgelaufen. Versuche es noch einmal.');
                })
                .finally(() => {
                    this.newCompany.name = null;
                    this.newCompany.colorHex = "#009688";
                    this.fetchAllCompanies();
                })
        }
    },
    mounted() {
        this.fetchAllCompanies();
    },
    data() {
        return {
            loadingCompanies: false,
            allCompanies: [],
            newCompany: {
                name: null,
                colorHex: "#009688"
            },
            swatches: [
                ["#F44336", "#E91E63", "#f9d423", "#9C27B0"],
                ["#673AB7", "#2B82C9", "#2196F3", "#03A9F4"],
                ["#607D8B", "#009688", "#4CAF50", "#8BC34A"],
                ["#CDDC39", "#FFC107", "#FF5722", "#795548"],
            ],
            required: [
                v => !!v || 'Dies ist ein Pflichtfeld',
            ],
            headers: [
                {
                    text: 'ID',
                    align: 'start',
                    value: 'id',
                    groupable: false,
                },
                {
                    text: 'Name',
                    align: 'start',
                    value: 'name',
                    groupable: false,
                },
                {
                    text: 'Color',
                    align: 'start',
                    value: 'colorHex',
                    groupable: false,
                }
            ],
        }
    },
}
</script>

<style scoped>

</style>