<template>
  <div>
    <div v-if="loading">
      <v-skeleton-loader
          v-for="(n, index) in 2"
          :key="index"
          class="mb-6"
          type="card"
      ></v-skeleton-loader>
    </div>
    <div v-else>
      <v-card class="card-transparent empty-notification d-flex flex-column justify-space-between align-center"
              v-if="!allEvents.length">
        <v-img
            width="500px"
            class="center"
            :src="require('@/assets/illustrations/scene-3-restaurant--bar.svg')">
        </v-img>
        <v-card-title>Hey, ganz schön leer hier.</v-card-title>
        <v-card-text class="text-center">Du und deine Kollegen haben heute scheinbar noch nichts geplant. <br>Vereinbare
          jetzt ein Treffen, egal ob zu Mittag oder Abend und entdecke Restaurants in Volkach und Umgebung.
        </v-card-text>
        <v-card-text class="d-flex justify-center align-center flex-column text-center">
          <v-btn fab
                 @click.stop="dialogCreateEvent = true"
          >
            <v-icon color="primary">
              mdi-plus
            </v-icon>
          </v-btn>
          <v-card-text>
            Vereinbare ein neues Treffen
          </v-card-text>
        </v-card-text>
        <new-event
            :dialogCreate="dialogCreateEvent"
            @dialogChanged="dialogCreateEvent = $event; fetchAllEventsFromOneCompany();"
        >
        </new-event>
      </v-card>
      <div
          v-else
          class="events-group"
      >
        <event-component
            v-for="event in allEvents" :key="event.id"
            class="mb-6 last-child-without-spacing"
            :event-prop="event"
        >

        </event-component>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import eventComponent from "../../components/event/eventComponent.vue";
import NewEvent from "../../components/event/newEvent.vue";

export default {
  name : "DashboardEventsList",
  components : {
    NewEvent,
    eventComponent
  },
  methods : {
    fetchAllEventsFromOneCompany() {
      this.loading = true;

      // check which Events are active
      axios.get(`/event/company/${this.$store.state.auth.user.company.id}/today`)
          .then((response) => {
            this.allEvents = response.data;
          })
          .catch((e) => {
            console.error(e);
            this.$toast.error('Beim Laden von Events ist etwas schiefgelaufen 😥');
          })
          .finally(() => {
            this.loading = false;
          })
    },
  },
  data() {
    return {
      loading : false,
      allEvents : [],
      dialogCreateEvent : false
    }
  },
  mounted() {
    return this.fetchAllEventsFromOneCompany();
  }
}
</script>

<style scoped lang="scss">

</style>