import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// custom
import vuetify from './plugins/vuetify'
import axios from 'axios'
import './styles/main.scss'
import toast from 'vuetify-toast-snackbar-ng'
import VueLuxon from "vue-luxon"
import _ from 'lodash'
import Printd from 'printd'

Vue.config.productionTip = false

axios.defaults.headers.get['Accepts'] = 'applications/json';
axios.defaults.baseURL = process.env.VUE_APP_BACKEND_API_URL;

// set Token to every request if needed
axios.interceptors.request.use(function (config) {
  const authToken = store.state.auth.token;
  if (authToken !== null) {
    config.headers['Authorization'] = 'Bearer ' + authToken;
  }
  return config;
});

axios.interceptors.response.use((response) => response, (error) => {
// logout user if expired JWT token
  if (error.response.status === 401 && (error.response.data.message === 'Expired JWT Token' || error.response.data.message === 'Invalid JWT Token')) {
    store.dispatch('logout');
    router.replace('/login');
  }
// default error message
  if (error.response.status === 500) {
    toast.error('Anmeldung fehlgeschlagen');
  }
  throw error;
});

Vue.use(VueLuxon, {
  input: {
    zone: "utc",
    format: "iso"
  },
  output: "short"
});

Vue.filter('priceFilter', function (value) {
  return value.toFixed(2).toString().replace(".",",");
})

new Vue({
  router,
  store,
  VueLuxon,
  vuetify,
  _,
  Printd,
  render: h => h(App)
}).$mount('#app')
