<template>
    <v-card
        :to="`/event/${eventProp.id}`"
        class="mx-auto card-default element-clickable"
        :class="{'event-not-active': !eventProp.active }"
    >
        <v-img
            height="350"
            dark
            :src="generateRestaurantCover(eventProp)"
            class="white--text align-end"
            :class="{'filter--gray-10': !eventProp.active }"
        >
            <v-app-bar
                class="app-bar-absolute"
                flat
                color="rgba(0, 0, 0, 0)"
            >
                <v-spacer></v-spacer>
<!--
TODO Das muss noch gemacht werden.
-->
<!--                <v-chip color="red" dark label v-if="HurryUp">-->
<!--                    {{ hurryUp.test }} {weniger als 1 Stunde zum Ende der Anmeldung} {{ currentDate }}-->
<!--                </v-chip>-->
            </v-app-bar>


            <v-card-title class="text-shadow--strong">
                <h3 class="text-h4" @click="$router.push('restaurant')">{{ eventProp.restaurant.name }}</h3> &nbsp; um
                {{eventProp.collectTime | luxon({ output: {format: "HH:mm"} } )}}
            </v-card-title>
            <!-- Participants -->
            <div v-if="loadingParticipants">
                <v-skeleton-loader
                    class="mb-6"
                    type="avatar"
                ></v-skeleton-loader>
            </div>
            <div v-else>
                <div class="participants-section text-shadow--default" v-if="allParticipants.length">
                    <v-card-text>Bereits angemeldete Kollegen:
                        <v-item-group class="participant-group mt-2 d-flex align-center">
                            <v-item
                                v-for="participant in allParticipants.slice(0, participantsLimit)"
                                :key="participant.id"
                                v-slot="{ active, toggle }"
                                class="participant-item"
                            >
                                <v-avatar
                                    color="secondary"
                                    size="66"
                                    @click="toggle"
                                >
                                    <v-img
                                        v-if="participant.avatarPath"
                                        :src="avatarSrc + participant.avatarPath">
                                    </v-img>
                                    <v-img
                                        v-else
                                        src="https://picsum.photos/100/100?blur=10">
                                    </v-img>
                                </v-avatar>
                            </v-item>
                            <v-chip v-if="calculateRest > 0" class="ml-3 glassy"> + {{ calculateRest }}</v-chip>
                        </v-item-group>
                    </v-card-text>
                </div>
            </div>
        </v-img>

        <!--            Event Closed -->
        <v-card-text class="event-not-active-card-info text-center "  v-if="!eventProp.active">
          <v-card-subtitle class="text-center glassy px-16 shadow--lighter"
                           style="border-radius: 8px; display: inline-block;">
            <v-chip color="success">Event abgeschlossen
              <v-icon right> mdi-check-circle</v-icon>
            </v-chip>
          </v-card-subtitle>
        </v-card-text>
    </v-card>
</template>

<script>
import axios from "axios";

export default {
    name: "eventComponent",
    props: ['eventProp'],
    methods: {
        fetchParticipantsFromEvent() {
            this.loadingParticipants = true;
            axios.get(`/event/${this.eventProp.id}/participants`)
                .then((response) => {
                    this.allParticipants = response.data;
                })
                .catch((e) => {
                    console.error(e);
                    this.$toast.error('Teilnehmer dieses Treffens konnten nicht geladen werden');
                })
            .finally(() => {
                this.loadingParticipants = false;

            })

        },
        generateRestaurantCover(event) {
            if (event.restaurant.coverUrl) {
                return process.env.VUE_APP_BACKEND_API_URL + event.restaurant.coverUrl;
            } else return process.env.VUE_APP_BACKEND_API_URL + '/assets/placeholder_cover.jpg';
        },
        HurryUp() {
            var today = new Date();
            var date = today.getFullYear()+'-'+(today.getMonth()+1)+'-'+today.getDate();
            var time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds() + "."+ today.getMilliseconds()+ "Z";
            var dateTime = '"'+date+'T'+time+'"';
            // "2020-10-05T14:48:00.000Z"
            console.log(dateTime);

            var now = this.$luxon(dateTime, 'YYYY.MM.dd HH:mm');
            this.hurryUp.test = now;
        }
    },
    mounted() {
        return this.fetchParticipantsFromEvent();
    },
    computed: {
        calculateRest() {
            return this.allParticipants.length - this.participantsLimit;
        },
    },
    data() {
        return {
            loadingParticipants: false,
            allParticipants: [],
            participantsLimit: 3,
            hurryUp: {
                test: null,
                color: 'red',
            },
            avatarSrc: process.env.VUE_APP_BACKEND_API_URL,
            // currentDate: luxon(),
        }
    }
}
</script>
<style scoped lang="scss">
.participant-group {
    .participant-item {

        border: solid 3px;
        border-color: #fff !important;;
        border-radius: 50%;
        box-shadow: -2px 2px 8px #00000010;

        &:not(:last-of-type) {
            margin-right: -1.5rem;
        }
    }
}

.app-bar-absolute {
    position: absolute;
    top: 0;
    right: 1rem;
}

.event-not-active-card-info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);


}
</style>