<template>
    <v-list-item class="participant-row"
    >
        <v-list-item-avatar>
            <v-img
                width="10"
                :src="avatarSrc + participant.avatarPath">
            </v-img>
        </v-list-item-avatar>

        <v-list-item-content>
            <v-list-item-title class="pt-3">{{ participant.firstname }} {{ participant.lastname }}
                <v-chip v-if="event.createdBy.id === participant.id" class="print-hidden" small outlined color="green">
                    🤴 Creator
                </v-chip>
            </v-list-item-title>
            <order-table-row
                v-for="(order, index) in allOrders"
                :key="index"
                @OrderDeleted="fetchUserOrders() && this.$emit('fetchParticipants')"
                @OrderUpdated="fetchUserOrders() && this.$emit('fetchParticipants')"
                :order="order"></order-table-row>
        </v-list-item-content>
    </v-list-item>
</template>

<script>
import OrderTableRow from "../order/OrderTableRow.vue";
import axios from "axios";

export default {
    name: "ParticipantTableRow",
    props: ['participant', 'event'],
    components: {
        OrderTableRow
    },
    methods: {
        fetchUserOrders() {
            this.loadingOrders = true;
            axios.get(`/order/event/${this.event.id}/user/${this.participant.id}`)
                .then((response) => {
                    this.allOrders = response.data;
                })
                .catch((e) => {
                    console.error(e);
                    this.$toast.error('Bestellungen von einigen Benutzer konnten nicht geladen werden');
                })
                .finally(() => {
                    this.loadingOrders = false;
                })

        }
    },
    mounted() {
        return this.fetchUserOrders();
    },
    data() {
        return {
            loadingOrders: false,
            allOrders: [],
            avatarSrc: process.env.VUE_APP_BACKEND_API_URL,
        }
    }
}
</script>

<style scoped lang="scss">
.participant-row {
    align-items: flex-start;
    background-color: transparent;

    .v-list-item__avatar {
        align-self: flex-start;
    }
}
</style>