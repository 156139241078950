<template>
    <v-form @submit="createNewCategory">
        <v-text-field
            filled
            rounded
            outlined
            dense
            :rules=required
            v-model="newCategory.name"
            :background-color="newCategory.color+'15'"
            placeholder="Fang an zu tippen..."
            :color="newCategory.color"
            :prepend-inner-icon="'mdi-'+newCategory.icon"
            append-outer-icon="mdi-plus"
            @click:append-outer="createNewCategory"
        >
        </v-text-field>
        <v-tabs
            v-model="tab"
            align-with-title
            centered
        >
            <v-tab
                v-for="item in items"
                :key="item.id"
            >
                <v-icon left>
                    {{ item.icon }}
                </v-icon>
                {{ item.title }}
            </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
            <v-tab-item>
                <v-card flat>
                    <v-card-text>
                        <v-item-group v-model="newCategory.icon">
                            <v-row>
                                <v-col
                                    v-for="(icon, index) in iconItems"
                                    :key="index"
                                    cols="3"
                                >
                                    <v-item
                                        :value="icon"
                                        v-slot="{ toggle }">
                                        <v-btn
                                            @click="toggle"
                                            class="element-clickable"
                                        >
                                            <v-icon>
                                                mdi-{{ icon }}
                                            </v-icon>
                                        </v-btn>
                                    </v-item>

                                </v-col>
                            </v-row>
                        </v-item-group>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card flat>
                    <v-card-text>
                        <v-color-picker
                            show-swatches
                            hide-canvas
                            hide-sliders
                            hide-mode-switch
                            hide-inputs
                            :swatches="swatches"
                            v-model="newCategory.color"
                        ></v-color-picker>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs-items>
    </v-form>
</template>

<script>
import axios from "axios";

export default {
    name: "NewCategory",
    methods: {
        createNewCategory() {
            this.newCategory.color = this.newCategory.color.substring(1);
            axios.post('/category', this.newCategory)
                .then(() => {
                    this.$toast.success('Toll! Deine neue Kategorie ist da! 🎨');
                })
                .catch((e) => {
                    console.error(e);
                    this.$toast.error('Etwas ist schiefgelaufen. Versuche es noch einmal.');
                })
                .finally(() => {
                    this.newCategory.name = null;
                    this.newCategory.color = "#009688";
                    this.newCategory.icon = "chili-mild";
                    this.$emit('fetchAllCategories');
                })
        }
    },
    data() {
        return {
            newCategory: {
                name: null,
                color: "#009688",
                icon: "chili-mild"
            },
            tab: null,
            items: [
                {title: 'Icon', icon: 'mdi-ice-cream'},
                {title: 'Farbe', icon: 'mdi-palette'},
            ],
            swatches: [
                ["#F44336", "#E91E63", "#f9d423", "#9C27B0"],
                ["#673AB7", "#2B82C9", "#2196F3", "#03A9F4"],
                ["#607D8B", "#009688", "#4CAF50", "#8BC34A"],
                ["#CDDC39", "#FFC107", "#FF5722", "#795548"],
            ],
            required: [
                v => !!v || 'Dies ist ein Pflichtfeld',
            ],
            iconItems: [
                'baguette',
                'chili-mild',
                'chili-medium',
                'chili-hot',
                'food-apple',
                'fruit-watermelon',
                'peanut',
                'leaf',
            ]
        }
    }
}
</script>

<style scoped>

</style>