import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist';

import authModel from './modules/auth/index.js'

Vue.use(Vuex);

const vuexLocal = new VuexPersistence({
  storage: window.localStorage,

  // Hier kommen alle Einstellungen hin, die persistent für diese Session gespeichert werden sollen
  // Die Einstellungen werden automatisch beim Aufrufen der Seite geladen
  reducer: (state) => ({
    auth: {
      token: state.auth.token,
      user: state.auth.user
    }
  })
});

export default new Vuex.Store({
  modules: {
    auth: authModel,
  },
  plugins: [vuexLocal.plugin]
})
