<template>
    <v-item :value="menu.id" v-slot="{ active, toggle }">
        <v-card
            :color="active ? '' : ''"
            class="d-flex align-center card-default element-clickable menu-card"
            @click="toggle"
        >

            <v-list-item>
                <v-list-item-content class="">
                    <v-list-item-title class="d-flex justify-start align-center flex-wrap"
                                       style="white-space: normal">
                        <span class="pr-2">{{ menu.title }}</span>
                        <v-chip-group
                            v-if="menu.category && menu.category.length >= 1"
                            class="py-0"
                        >
                            <v-chip
                                v-for="category in menu.category" :key="category.id"
                                outlined
                                small
                                :color='"#" + category.color'
                                class="my-0"
                            >
                                <v-icon
                                    small
                                    v-if="category.icon"
                                    left>
                                    {{ 'mdi-' + category.icon }}
                                </v-icon>
                                {{ category.name }}
                            </v-chip>
                        </v-chip-group>
                    </v-list-item-title>

                    <v-list-item-subtitle style="white-space: normal">
                        {{ menu.description }}
                        <br v-if="menu.description">
                        <span class="text-body-2 success--text">+ {{
                                menu.points
                            }} Punkte</span>
                    </v-list-item-subtitle>
                </v-list-item-content>

                <v-list-item-action class="">
                    {{ menu.price | priceFilter }} €
                </v-list-item-action>

            </v-list-item>
            <div
                v-if='$store.state.auth.user.roles.find(role => role === "ROLE_ADMIN")'
                class="show-on-card-hover v-btn--absolute">

                <v-btn
                    fab
                    x-small
                    color="amber"
                    dark
                    class="mr-2"
                    @click.prevent="dialogEditMenu = true"
                >
                    <v-icon small>
                        mdi-pencil
                    </v-icon>

                    <EditMenu
                        :dialog-edit="dialogEditMenu"
                        :restaurant-data="restaurantData"
                        @dialogChanged="dialogEditMenu = $event;"
                        :menu="menu"

                    ></EditMenu>
                </v-btn>
                <v-btn
                    fab
                    x-small
                    color="error"
                    @click.prevent="deleteMenu()"
                >
                    <v-icon small>
                        mdi-delete
                    </v-icon>
                </v-btn>
            </div>
        </v-card>
    </v-item>
</template>

<script>
import axios from "axios";
import EditMenu from "./EditMenu.vue";

export default {
    name: "MenuItem",
    components: {EditMenu},
    props: ['restaurantData', 'menu'],
    methods: {
        deleteMenu() {
            this.loadingRemove = true;
            axios.delete(`/menu/${this.menu.id}`)
                .then(() => {
                    this.fetchMenu();
                    this.$toast.success(`Speise erfolgreich gelöscht.`);
                })
                .catch(() => {
                    this.$toast.error(`🚫 Du hast anscheinend keine Rechte, die Speise zu löschen.`)
                })
                .finally(() => {
                    this.loadingRemove = false;
                })
        }
    },
    data() {
        return{
            loadingRemove: false,
            dialogEditMenu: false,
        }
    }
}
</script>

<style scoped>

.show-on-card-hover {
    right: .25rem !important;
    top: 100%;
}
</style>