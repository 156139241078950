import Vue from 'vue';
import Vuetify, { VSnackbar, VBtn, VIcon } from 'vuetify/lib'
import '@mdi/font/css/materialdesignicons.css'
import VuetifyToast from 'vuetify-toast-snackbar-ng'

Vue.use(Vuetify,
    {
        components: {
            VSnackbar,
            VBtn,
            VIcon
        }
    })

const opts = {
    theme: {
        themes: {
            light: {
                primary: '#0A2E3E',
                secondary: '#A8BB31',
                // accent: '#F4F5FA',
                accent: '#2d45bd',
                error: '#FF5252',
                info: '#2196F3',
                success: '#A8BB31',
                warning: '#FFC107',
            },
        },
    },
}
Vue.use(VuetifyToast, {
    classes: [
        'z-index-10'
    ],
    timeout: 6000,
    multiLine: true,
    color: 'grey',
    showClose: true,
    closeIcon: 'mdi-close',

})

export default new Vuetify(opts)