<template>
    <v-row>
        <v-col
            cols="12"
        >
            <DashboardWelcomeCard></DashboardWelcomeCard>
        </v-col>

        <v-col
            cols="12"
            md="8"
        >
            <dashboard-participants-card ref="eventList"></dashboard-participants-card>
        </v-col>

        <v-col
            cols="12"
            md="4"
        >
            <v-row>
                <v-col cols="12">
                    <v-card class="card-default pa-16 d-flex justify-center align-center flex-column text-center">
                        <v-btn fab
                               @click.stop="dialogCreateEvent = true"
                        >
                            <v-icon color="primary">
                                mdi-plus
                            </v-icon>
                        </v-btn>
                        <v-card-text>
                            Vereinbare ein neues Treffen
                        </v-card-text>
                    </v-card>
                    <new-event
                        :dialogCreate="dialogCreateEvent"
                        @dialogChanged="dialogCreateEvent = $event; fetchAllEvents();"
                    >

                    </new-event>
                </v-col>

                <v-col cols="12">
                    <v-card class="card-default">
                        <v-card-title>Deine Rechnungen</v-card-title>
                        <v-card-text>
                            Keine offene Rechnungen
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">

                                    <v-btn
                                        elevation="0"
                                        v-bind="attrs"
                                        v-on="on"
                                        disabled
                                        >aktivität anzeigen</v-btn>
                                </template>
                                <span>Diese Funktion ist vorläufig deaktiviert</span>
                            </v-tooltip>
                        </v-card-actions>
                    </v-card>
                </v-col>
            </v-row>
        </v-col>

        <v-col
            cols="12"
            md="8"
        >
            <DashboardYourOrderCard></DashboardYourOrderCard>
        </v-col>
        <v-col cols="12" md="4">
            <v-img
                :src="require('@/assets/illustrations/scene-1-transparent.svg')"
                max-height="200px"
                max-width="300px"
                alt="Scene 1 Hunger"
                class="mr-auto ml-auto"
            ></v-img>
        </v-col>

    </v-row>
</template>

<script>
import DashboardWelcomeCard from "./DashboardWelcomeCard.vue";
import DashboardParticipantsCard from "./DashboardEventsList.vue";
import DashboardYourOrderCard from "./DashboardYourOrderCard.vue";
import newEvent from "../../components/event/newEvent.vue";

export default {
    components: {DashboardWelcomeCard, DashboardYourOrderCard, DashboardParticipantsCard, newEvent},
    methods: {
      fetchAllEvents() {
          this.$refs.eventList.fetchAllEventsFromOneCompany();
      }
    },
    data() {
        return {
            dialogCreateEvent: false,
        }
    },
}
</script>
