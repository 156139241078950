<template>
    <v-card class="card-default">
        <v-card-title>Deine letzte Bestellungen</v-card-title>
        <v-card-text>
            <v-sheet
                class="mx-auto"
            >
                <div v-if="allOrders.length < 1">
                    <v-card class="card-transparent py-4">
                        <v-card-text class="text-center">
                            Schade... Deine Bestellhistorie ist noch leer!
                            <v-img
                                class="mx-auto my-4"
                                :src="require('@/assets/illustrations/scene-8-sad-face.svg')"
                                width="150px"
                            ></v-img>
                        </v-card-text>
                    </v-card>

                </div>
                <v-slide-group
                    multiple
                    show-arrows
                >
                    <v-slide-item
                        v-for="(order,index) in allOrders"
                        :key="index"
                    >
                        <v-card
                            @click="$router.push('order/' + order.id)"
                            class="mx-2 card-grey d-flex flex-column"
                            style="width: 180px"
                        >

                            <v-card-text class="d-flex justify-center pb-0">
                                <v-avatar class="shadow--lighter" size="64" color="white"><v-img
                                    alt="dish"
                                    :src="require('@/assets/illustrations/scene-4-dish.svg')">
                                </v-img></v-avatar>
                            </v-card-text>
                            <v-card-subtitle class="pb-2">
                                {{ order.menu.title }} <br>
                                <span class="grey--text text--lighten-1 text-caption">{{ order.menu.restaurant.name }}</span>
                            </v-card-subtitle>
                            <v-divider class=" mt-auto "></v-divider>
                            <v-card-subtitle class="pt-3 d-flex justify-space-between grey--text text--lighten-1 text-caption">
                                <span class="">{{ order.createdAt | luxon({ output: {format: "dd.MM.yyyy"} } )  }}</span>
                                <span class="success--text">+{{ order.menu.points }} Punkte</span>
                            </v-card-subtitle>
                        </v-card>

                    </v-slide-item>
                </v-slide-group>
            </v-sheet>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions v-if="allOrders.length > 0">
            <v-spacer></v-spacer>
            <v-btn :to="{name:'Order'}" elevation="0">aktivität anzeigen</v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
import axios from "axios";
export default {
    name: "DashboardYourOrderCard",
    methods: {
        fetchOrdersFromUser() {
            this.loadingOrders = true;
            axios.get(`/order/user/${this.$store.state.auth.user.id}`)
                .then((response) => {
                    this.allOrders = response.data;
                })
                .catch((e) => {
                    console.error(e);
                    this.$toast.error('Beim Laden von deinen Bestellungen ist etwas schiefgelaufen 😥');
                })
                .finally(() => {
                    this.loadingOrders = false;
                })

        }
    },
    mounted() {
        return this.fetchOrdersFromUser();
    },
    data() {
        return {
            allOrders:[],
            loadingOrders: false,
        }
    },
}
</script>

<style scoped>

</style>