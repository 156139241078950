<template>
    <v-data-table
        :headers="headers"
        :items="allUsers"
        item-key="id"
        sort-by="createdAt"
        group-by="company.name"
        class="elevation-1"
        show-group-by
        :items-per-page="20"
    ></v-data-table>
</template>

<script>
import axios from "axios";

export default {
    name: "AdminUserTable",
    methods: {
        fetchAllUsers() {
            this.loadingUsers = true;
            axios.get(`/user`)
                .then((response) => {
                    this.allUsers = response.data;
                })
                .catch((e) => {
                    console.error(e);
                    this.$toast.error('Benutzer konnten nicht geladen werden');
                })
            .finally(() => {
                this.loadingUsers = false
            })
        }
    },
    mounted() {
        this.fetchAllUsers();
    },
    data () {
        return {
            loadingUsers: false,
            allUsers: [],
            headers: [
                {
                    text: 'ID',
                    align: 'start',
                    value: 'id',
                    groupable: false,
                },
                {
                    text: 'Vorname',
                    align: 'start',
                    value: 'firstname',
                    groupable: false,
                },
                {
                    text: 'Nachname',
                    align: 'start',
                    value: 'lastname',
                    groupable: false,
                },
                {
                    text: 'E-Mail',
                    align: 'start',
                    value: 'email',
                    groupable: false,
                },
                {
                    text: 'Points',
                    align: 'start',
                    value: 'points',
                    groupable: false,
                },
                {
                    text: 'Rollen',
                    align: 'start',
                    value: 'roles',
                    groupable: false,
                },
                {
                    text: 'Stelle',
                    align: 'start',
                    value: 'userRole.name',
                    groupable: true,
                },
                {text: 'Company', value: 'company.name', align: 'right'},
            ],
        }
    },
}
</script>

<style scoped>

</style>