<template>
    <v-card elevation="0" class="pa-4 mt-2 card-default order-card">
        <v-list-item>
            <v-list-item-content>
                <v-list-item-subtitle class="mb-0 print-hidden">
                    <span class="text-overline">
                        {{ order.menu.menuGroup.title }}
                    </span>
                </v-list-item-subtitle>
                <v-list-item-title class="d-flex justify-start align-center flex-wrap mb-3"
                                   style="white-space: normal"
                >
                    <span
                        class="print-visibility">
                    <strong>✅ Bestellung: </strong>
                </span>
                    <span class="pr-2">
                        {{ order.menu.title }}
                    </span>
                    <v-chip-group
                        v-if="order.menu.category && order.menu.category.length >= 1"
                        class="py-0 print-hidden"
                    >
                        <v-chip
                            v-for="category in order.menu.category" :key="category.id"
                            outlined
                            small
                            :color='"#" + category.color'
                            class="my-0"
                        >
                            <v-icon
                                small
                                v-if="category.icon"
                                left>
                                {{ 'mdi-' + category.icon }}
                            </v-icon>
                            {{ category.name }}
                        </v-chip>
                    </v-chip-group>
                </v-list-item-title>
                <v-list-item-subtitle
                    class="show-edit-icon-on-hover d-flex align-center"
                    :class="{'print-hidden' : !order.comment}"
                >
                    <v-icon color="grey lighten-1" class="mr-3"> mdi-comment-plus-outline</v-icon>
                    <span
                        v-if="order.comment"
                        class="print-visibility">
                        <strong>Kommentar: </strong>
                    </span>
                    <span
                        v-if="!showCommentUpdateField && order.comment">
                        {{ order.comment }}
                    </span>
                    <span class="grey--text text--lighten-1" @click="showCommentUpdateField = true"
                          v-if="!showCommentUpdateField && !order.comment">
                        Extrawünsche...
                    </span>
                    <span
                        v-if="order.user.id === $store.state.auth.user.id || isAdmin()"
                        style="flex: 1"
                    >

                    <v-form
                        @submit.prevent="updateOrder"
                        v-if="showCommentUpdateField"
                        class="d-flex align-center pr-6"
                        v-click-outside="clickOutsideEditableFeld"
                    >
                        <v-text-field
                            label="Kommentar"
                            @keydown.esc="clickOutsideEditableFeld"
                            dense
                            hide-details
                            outlined
                            v-model="updatedOrder.comment"
                            class="text-caption"
                        >

                        </v-text-field>
                        <v-btn
                            v-if="showCommentUpdateField"
                            class="edit-icon ml-1"
                            icon
                            small
                            color="primary"
                            outlined
                            type="submit"
                        >
                            <v-icon small>
                                mdi-check
                            </v-icon>
                        </v-btn>
                        <v-btn
                            v-if="showCommentUpdateField"
                            class="edit-icon ml-1"
                            icon
                            small
                            outlined
                            color="grey lighten-1"
                            @click="clickOutsideEditableFeld"
                        >
                            <v-icon small>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-form>

                    <v-btn
                        v-if="!showCommentUpdateField"
                        class="edit-icon ml-1 print-hidden"
                        icon
                        small
                        color="amber"
                        @click="showCommentUpdateField = true"
                    >
                        <v-icon small>
                            mdi-pencil
                        </v-icon>
                    </v-btn>
</span>
                </v-list-item-subtitle>
                <v-list-item-subtitle
                    class="show-edit-icon-on-hover d-flex align-center"
                    :class="{'print-hidden' : !order.excluding}">
                    <v-icon color="grey lighten-1" class="mr-3"> mdi-close-octagon-outline</v-icon>
                    <span
                        class="print-visibility">
                        <strong>Ohne: </strong>
                    </span>
                    <span v-if="!showExcludingUpdateField && order.excluding">
                        {{ order.excluding }}
                    </span>
                    <span class="grey--text text--lighten-1" @click="showExcludingUpdateField = true"
                          v-if="!showExcludingUpdateField && !order.excluding">
                        Füge Ausnahme hinzu...
                    </span>
                    <span
                        v-if="order.user.id === $store.state.auth.user.id || isAdmin()"
                        style="flex: 1"
                    >

                    <v-form
                        @submit.prevent="updateOrder"
                        v-if="showExcludingUpdateField"
                        class="d-flex align-center pr-6"
                        v-click-outside="clickOutsideEditableFeld"
                    >
                        <v-text-field
                            label="Ohne:"
                            @keydown.esc="clickOutsideEditableFeld"
                            dense
                            hide-details
                            outlined
                            v-model="updatedOrder.excluding"
                            class="text-caption"
                        >

                        </v-text-field>
                        <v-btn
                            v-if="showExcludingUpdateField"
                            class="edit-icon ml-1"
                            icon
                            small
                            color="primary"
                            outlined
                            type="submit"
                        >
                            <v-icon small>
                                mdi-check
                            </v-icon>
                        </v-btn>
                        <v-btn
                            v-if="showExcludingUpdateField"
                            class="edit-icon ml-1"
                            icon
                            small
                            outlined
                            color="grey lighten-1"
                            @click="clickOutsideEditableFeld"
                        >
                            <v-icon small>
                                mdi-close
                            </v-icon>
                        </v-btn>
                    </v-form>

                    <v-btn
                        v-if="!showExcludingUpdateField"
                        class="edit-icon ml-1 print-hidden"
                        icon
                        small
                        color="amber"
                        @click="showExcludingUpdateField = true"
                    >
                        <v-icon small>
                            mdi-pencil
                        </v-icon>
                    </v-btn>
</span>
                </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
                {{ order.menu.price | priceFilter }} €
            </v-list-item-action>
            <v-list-item-action
                v-if="order.user.id === $store.state.auth.user.id || isAdmin()"
                class="show-on-card-hover v-btn--absolute print-hidden">
                <v-btn
                    fab
                    x-small
                    color="error"
                    @click="deleteOrder"
                >
                    <v-icon small>
                        mdi-delete
                    </v-icon>
                </v-btn>
            </v-list-item-action>
        </v-list-item>
    </v-card>
</template>

<script>

import axios from "axios";

export default {
    name: "OrderTableRow",
    props: ['order'],
    methods: {
        isAdmin() {
            return this.userData.roles.find(role => role === "ROLE_ADMIN");
        },
        deleteOrder() {
            this.loadingRemove = true;
            axios.delete(`/order/${this.order.id}`)
                .then(() => {
                    this.$emit('OrderDeleted');
                    this.$toast.success(`Deine Bestellung <span class="font-weight-bold">"${this.order.menu.title}"</span> wurde erfolgreich gelöscht.`);
                })
                .catch(() => {
                    this.$toast.error(`🚫 Du hast anscheinend keine Rechte, die Bestellung <span class="font-weight-bold">"${this.order.menu.title}"</span> zu löschen.`)
                })
                .finally(() => {
                    this.loadingRemove = false;
                })
        },
        updateOrder() {
            this.loadingUpdate = true;
            axios.put(`/order/${this.order.id}`, this.updatedOrder)
                .then(() => {
                    this.$emit('OrderUpdated');
                    this.$toast.success(`Success! ✌ Die Bestellung "${this.order.menu.title}" wurde aktualisiert.`);
                    this.showCommentUpdateField = false;
                    this.showExcludingUpdateField = false;
                })
                .catch(() => {
                    this.$toast.error('Etwas ist schief gelaufen - versuch es einfach noch einmal.');
                    this.mappOrderProperty();
                })
                .finally(() => {
                    this.loadingUpdate = false;
                })
        },
        clickOutsideEditableFeld() {
            if (this.showCommentUpdateField === true) {
                this.showCommentUpdateField = false;
                this.mappOrderProperty();
            }
            if (this.showExcludingUpdateField === true) {
                this.showExcludingUpdateField = false;
                this.mappOrderProperty();
            }
        },
        mappOrderProperty() {
            this.updatedOrder.event = this.order.event.id;
            this.updatedOrder.menu = this.order.menu.id;
            this.updatedOrder.user = this.order.user.id;
            this.updatedOrder.excluding = this.order.excluding;
            this.updatedOrder.comment = this.order.comment;
        }
    },

    mounted() {
        return this.mappOrderProperty();
    },

    data() {
        return {
            userData: this.$store.state.auth.user,
            loadingRemove: false,
            loadingUpdate: false,
            updatedOrder: {
                event: {},
                menu: {},
                user: {},
                excluding: null,
                comment: null,
            },
            showCommentUpdateField: false,
            showExcludingUpdateField: false,
        }
    }
}
</script>

<style scoped lang="scss">
.show-on-card-hover {

}

.show-edit-icon-on-hover {
    .edit-icon {
        opacity: 0;
        pointer-events: none;
    }

    &:hover {
        .edit-icon {
            opacity: 1;
            pointer-events: auto;
        }
    }
}
</style>