<template>
    <v-card class="card-default">
        <v-card-text>

            <v-container>
                <v-row>
                    <!--                        Your Profile Image -->
                    <v-col cols="12" class="d-flex align-center" style="gap: 2rem;">
                        <v-avatar size="128" rounded>
                            <v-img
                                v-if="newAvatarUrl"
                                width="120px"
                                :src="newAvatarUrl" alt="">
                            </v-img>
                            <v-img
                                v-else
                                width="120px"
                                :src="userAvatar">
                            </v-img>
                        </v-avatar>

                        <!--                            New Profile image -->
                        <v-dialog
                            v-model="dialog"
                            width="800"
                            scrollable
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    color="primary"
                                    v-bind="attrs"
                                    v-on="on"
                                >
                                    Profilbild ändern
                                </v-btn>
                            </template>

                            <v-card class="card-default">
                                <v-card-title class="text-h5 font-weight-bold">
                                    Profilbild ändern
                                </v-card-title>
                                <v-card-subtitle>
                                    Lade dein eigenes Profilbild hoch oder wähle eins aus der Galerie aus.
                                </v-card-subtitle>
                                <v-divider></v-divider>
                                <v-card-text style="height: 400px">
                                    <div class="text-center pt-12 pb-8 px-16">
                                        <v-file-input
                                            v-model="newAvatarFile"
                                            :rules="avatarRules"
                                            @change="onFileChange"
                                            accept="image/png, image/jpeg, image/svg"
                                            outlined
                                            class="mx-16"
                                            placeholder="Wähle einen Avatar"
                                            prepend-icon="mdi-camera"
                                            label="Avatar"
                                            show-size
                                            persistent-hint
                                            hint="Folgende Bilddateien sind erlaubt: jpg, png, svg. Die Bildgröße darf nicht 2MB überschreiten."
                                        ></v-file-input>

                                    </div>

                                    <!--                                          Divider -->
                                    <div class="d-flex align-center">
                                        <v-divider></v-divider>
                                        <span class="mx-2 grey--text text-overline">oder</span>
                                        <v-divider></v-divider>
                                    </div>

                                    <!--                                        Avatars select-->
                                    <div>

                                        <v-item-group
                                            v-model="userData.selectedAvatar"
                                            mandatory
                                            v-bind:class="{'opacity-50': newAvatarFile }"
                                        >
                                            <v-row>
                                                <v-col
                                                    v-for="(item, i) in 8"
                                                    :key="i"
                                                    cols="6"
                                                    md="4"
                                                    lg="3"
                                                >
                                                    <v-item v-slot="{ active, toggle }">
                                                        <v-img
                                                            :src="require(`@/assets/avatars/avatar_0${i+1}.svg`)"
                                                            height="150"
                                                            style="border-radius: 4px"
                                                            class="pa-2"
                                                            @click="toggle"
                                                        >
                                                            <v-btn
                                                                icon
                                                                dark
                                                            >
                                                                <v-icon>
                                                                    {{
                                                                        active ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'
                                                                    }}
                                                                </v-icon>
                                                            </v-btn>
                                                        </v-img>
                                                    </v-item>
                                                </v-col>
                                            </v-row>
                                        </v-item-group>
                                    </div>
                                </v-card-text>
                                <v-divider></v-divider>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn
                                        elevation="0"
                                        @click="dialog = false"
                                    >
                                        Abbrechen
                                    </v-btn>
                                    <v-btn
                                        color="primary"
                                        @click="updateUserAvatar"
                                    >
                                        Speichern
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-dialog>
                    </v-col>
                </v-row>

            </v-container>
            <v-form v-model="valid" @submit.prevent="updateUserData()">
                <v-container>
                    <v-row>
                        <v-col
                            cols="12"
                            md="6"
                        >
                            <v-text-field
                                v-model="userData.firstname"
                                label="Vorname"
                                outlined
                                required
                            ></v-text-field>
                        </v-col>

                        <v-col
                            cols="12"
                            md="6"
                        >
                            <v-text-field
                                v-model="userData.lastname"
                                label="Nachname"
                                outlined
                                required
                            ></v-text-field>
                        </v-col>

                        <v-col
                            cols="12"
                        >
                            <v-text-field
                                v-model="userData.email"
                                label="E-mail-Adresse"
                                outlined
                                required
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn type="submit" color="primary">
                        Speichern
                    </v-btn>
                </v-card-actions>
            </v-form>
        </v-card-text>
    </v-card>

</template>

<script>

import axios from "axios";

export default {
    components: {},
    data() {
        return {
            dialog: false,
            newAvatarUrl: null,
            newAvatarFile: null,
            userAvatar: process.env.VUE_APP_BACKEND_API_URL + this.$store.state.auth.user.avatarPath,
            avatarRules: [
                value => !value || value.size < 2000000 || 'Die Größe des Avatars sollte weniger als 2 MB betragen!',
            ],
            valid: false,
            userData: {
                firstname: this.$store.state.auth.user.firstname,
                lastname: this.$store.state.auth.user.lastname,
                email: this.$store.state.auth.user.email,
                selectedAvatar: null,
            }
        }
    },
    methods: {
        async updateUserAvatar() {
            this.loading = true;
            if (this.newAvatarFile) {
                const formData = new FormData();
                formData.append('file', this.newAvatarFile);
                // formData.append('data', JSON.stringify(this.newAvatar));
                axios.post(`/user/${this.$store.state.auth.user.id}/avatar`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
                    .then(() => {
                        this.$toast.success('🎉 Erfolgreich gespeichert');
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast.error('Dein Profilbild konnte nicht aktualisiert werden.');
                    })
                    .finally(() => {
                        this.$store.dispatch('loadNewUserData');
                        this.dialog = false;
                        this.loading = false
                    })
            } else if (this.userData.selectedAvatar) {
                axios.put(`/user/${this.$store.state.auth.user.id}`,
                    {
                        selectedAvatar: this.userData.selectedAvatar,
                    })
                    .then(() => {
                        this.$toast.success('🎉 Erfolgreich gespeichert');
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast.error('Dein Profilbild konnte nicht aktualisiert werden.');
                    })
                    .finally(() => {
                        this.$store.dispatch('loadNewUserData');
                        this.dialog = false;
                        this.loading = false
                    })
            }
        },
        updateUserData() {
            axios.put(`/user/${this.$store.state.auth.user.id}`,
                {
                    firstname: this.userData.firstname,
                    lastname: this.userData.lastname,
                    email: this.userData.email,
                })
                .then(() => {
                    this.$toast.success('Success - update user data');
                    this.$store.dispatch('loadNewUserData');
                })
                .catch((err) => {
                    console.error(err);
                    this.$toast.error('Error - update user');
                })
        },
        onFileChange(e) {
            //  const file = e.target.files[0]; // use it in case of normal HTML input
            const file = e; // in case vuetify file input
            if (file) {
                this.newAvatarUrl = URL.createObjectURL(file);
                URL.revokeObjectURL(file); // free memory
            } else {
                this.newAvatarUrl = null
            }
        }
    },
    mounted() {
    },
}
</script>
