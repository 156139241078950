<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-card class="card-transparent">
                    <v-card-title class="text-h5 font-weight-bold">Restaurants</v-card-title>
                    <v-card-subtitle>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod
                        tempor
                        invidunt ut labore et dolore magna.
                    </v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>
        <v-row class="searchbar-wrapper">
            <v-col cols="12" md="6">
                <v-text-field
                    v-model="search"
                    prepend-inner-icon="mdi-magnify"
                    placeholder="Suche nach dem Restaurantnamen"
                    outlined
                    clearable
                    hide-details
                >
                </v-text-field>
            </v-col>
        </v-row>
        <div class="loaders" v-if="loadingRestaurants">
            <v-row v-for="n in 2" :key="n">
                <v-col cols="12">
                    <v-skeleton-loader
                        type="table-heading"
                        class="bg-transparent"
                    ></v-skeleton-loader>
                </v-col>
                <v-col
                    v-for="n in 5" :key="n"
                    cols="6"
                    md="4"
                >
                    <v-skeleton-loader
                        type="card"
                    ></v-skeleton-loader>
                </v-col>
            </v-row>
        </div>
        <div
            v-else
            class="pt-3"
            v-for="(group, index) in groups"
            :key="index"
        >
            <v-item-group>
                <div>
                    <h2
                        v-if="group[0].city"
                        class="pb-3"
                    >{{ group[0].city }}
                    </h2>
                    <h2 v-else
                        class="pb-3">
                        Andere <span class="text-body-1">(keine Stadt zugewiesen)</span>
                    </h2>
                    <v-row>
                        <v-col
                            cols="12" sm="6" md="4"
                            v-for="restaurant in group"
                            :key="restaurant.id"
                        >
                            <RestaurantComponent
                                :restaurant="restaurant"
                                @updateRestaurants="fetchRestaurants()"
                            ></RestaurantComponent>
                        </v-col>
                    </v-row>
                </div>
            </v-item-group>
        </div>

        <v-row>
            <v-col
                cols="12" sm="6" md="4"
                v-if='$store.state.auth.user.roles.find(role => role === "ROLE_ADMIN")'
            >
                <v-card class="card-default pa-16 d-flex justify-center align-center flex-column text-center"
                        height="250">
                    <v-btn fab
                           @click.stop="dialogCreateRestaurant = true"
                    >
                        <v-icon color="primary">
                            mdi-plus
                        </v-icon>
                    </v-btn>
                    <v-card-text>
                        Create neues Restaurant
                    </v-card-text>
                </v-card>
                <new-restaurant
                    :dialogCreate="dialogCreateRestaurant"
                    @dialogChanged="dialogCreateRestaurant = $event;"
                    @updateRestaurants="fetchRestaurants()"
                >

                </new-restaurant>
            </v-col>
            <!--        Question Form -->
            <v-col cols="12">
                <v-card class="card-transparent pa-16 d-flex justify-center align-center flex-column text-center">
                    <v-icon color="primary">
                        mdi-comment-question
                    </v-icon>
                    <v-card-text>
                        Welcher Ort fehlt es dir? Lass uns wissen.
                    </v-card-text>
                    <v-text-field
                        v-model="mail.message"
                        outlined
                        :append-outer-icon="mail.message ? 'mdi-send': ''"
                        placeholder="zB. Ristorante Corleone"
                        @click:append-outer="sendMessage">
                    </v-text-field>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import axios from "axios";
import NewRestaurant from "../../components/restaurant/NewRestaurant.vue";
import _ from "lodash";
import RestaurantComponent from "../../components/restaurant/RestaurantComponent.vue";

export default {
    name: "Restaurant",
    components: {
        RestaurantComponent,
        NewRestaurant
    },
    methods: {
        fetchRestaurants() {
            this.loadingRestaurants = true;
            axios.get(`/restaurant`)
                .then((response) => {
                    this.restaurants = response.data;
                })
                .catch((e) => {
                    console.error(e);
                    this.$toast.error('👎 Nicht alle Restaurants konnten geladen werden.');
                })
                .finally(() => {
                    this.loadingRestaurants = false;
                })
        },
        sendMessage() {
            axios.post('/restaurant/feedback', this.mail.message)
                .then(() => {
                    this.$toast.success('Danke für Dein Feedback!');
                })
                .catch((e) => {
                    console.error(e);
                    this.$toast.error(`Mail konnte nicht versendet werden. Bitte versuche nochmal.`);
                })
                .finally(() => {
                    this.mail.message = null;
                })
        },
    },
    mounted() {
        return this.fetchRestaurants();
    },
    computed: {
        groups() {
            return _.groupBy(this.RestaurantFiltered, 'city')
        },
        RestaurantFiltered() {
            // wenn search ist leer, return alles
            if (!this.search) {
                return this.restaurants
            }
            // filter on restaurantData
            return this.restaurants.filter(restaurant => restaurant.name.toLowerCase().includes(this.search.toLowerCase()))
        }
    },
    data() {
        return {
            loadingRestaurants: false,
            restaurants: [],
            mail: {
                message: null
            },
            search: '',
            userData: this.$store.state.auth.user,
            dialogCreateRestaurant: false,
        }
    }
}
</script>

<style scoped lang="scss">
.searchbar-wrapper {
    position: sticky;
    top: 0;
    z-index: 33;
    background-color: transparent;

    .v-input {
        background-color: white;
    }
}

</style>