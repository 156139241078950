<template>
    <v-card-text>
        <v-form
            v-model="valid"
            @submit.prevent="createOrder()"
        >
            <v-row>
                <v-col cols="12"
                       v-if="restaurantMapper" class="pt-0" style="max-height: 60vh; overflow: auto">
                    <MenuList
                        :restaurant-data='restaurantMapper'
                        :cols-settings-md="4"
                        @selectedPosition="orderData.menu = $event"
                    ></MenuList>


                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        outlined
                        id="comment"
                        label="Kommentar"
                        placeholder="Reis statt Pommes"
                        name="comment"
                        v-model="orderData.comment">
                    </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <v-text-field
                        outlined
                        id="excluding"
                        label="ohne"
                        placeholder=""
                        name="excluding"
                        v-model="orderData.excluding">
                    </v-text-field>
                </v-col>
                <v-col cols="12" md="4">
                    <!-- Sumbit Button -->
                    <v-btn
                        type="submit"
                        block
                        x-large
                        class="mt-1"
                        color="primary"
                        :loading="loading"
                        :disabled="loading || !valid"
                    >
                        Speichern
                    </v-btn>
                </v-col>
            </v-row>
        </v-form>
    </v-card-text>

</template>

<script>
import axios from "axios";
import MenuList from "../menu/MenuList.vue";

export default {
    props: ['eventProp'],
    name: "NewOrderForm",
    components: {
        MenuList
    },
    methods: {
        createOrder() {
            axios.post('/order', this.orderData)
                .then(() => {
                    this.$toast.success('Prima! 👌 Deine Bestellung wurde angelegt.');
                })
                .catch((e) => {
                    console.error(e);
                    this.$toast.error('Während der Erstellung deiner Bestellung ist ein Fehler aufgetreten.');
                })
                .finally(() => {
                    this.$emit('OrderCreated');
                    this.resetData();
                })
        },
        resetData() {
            this.orderData.comment = null;
            this.orderData.excluding = null;
            this.orderData.menu = null;
        }
    },
    data() {
        return {
            loading: false,
            valid: true,
            menuDialog: false,
            orderData: {
                comment: null,
                excluding: null,
                menu: null,
                user: this.$store.state.auth.user.id,
                event: this.eventProp.id
            },
            restaurantMapper: this.eventProp.restaurant,
        }
    },
    mounted() {
    }
}
</script>

<style scoped>

</style>