<template>
  <div id="invoice"></div>
</template>

<script>
import axios from "axios";
import Printd from "printd";
export default {
  name : "EventInvoice",
  methods: {
    generateInvoice() {
      axios.get(`/invoice/generate/html/${this.$route.params.id}`)
      .then((response) => {
        this.$toast.success('Generiert');
        const invoiceHtml = document.getElementById('invoice');
        invoiceHtml.innerHTML = response.data;
        const d = new Printd()
        d.print(this.$el);
        window.onafterprint = function(){
          invoiceHtml.innerHTML = "";
          console.log("Here we are")
        }
      })
      .catch((e) => {
        console.error(e);
        this.$toast.error('error.');
      })
      .finally(() => {
        this.loadingParticipants = false;
      })
    }
  },
  mounted() {
    return this.generateInvoice();
  }
}
</script>

<style scoped>

</style>