<template>
  <div>
  <v-row style="position: relative">
    <v-col cols="12">
      <v-card class="card-transparent">
        <v-card-title class="text-h5 font-weight-bold">Bestellungen & Events</v-card-title>
        <v-card-subtitle>Liste deiner kürzlich angelegten Bestellungen und aller Events von {{ $store.state.auth.user.company.name }}.
        </v-card-subtitle>
      </v-card>
    </v-col>
  </v-row>


    <v-row>
<!--      orders-->
      <v-col cols="12" md="6">
        <div class="loaders" v-if="loadingOrders">
          <v-row v-for="n in 10" :key="n">
            <v-col cols="12">
              <v-skeleton-loader
                  type="table-heading"
                  class="bg-transparent"
              ></v-skeleton-loader>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <v-card
              class=" card-default"
          >
            <div class="" style="gap: 1rem; position: relative">
              <v-card-text style="">
                <p class=" mb-1">Lege eine neue Bestellung an</p>
                <v-btn
                    elevation="0"
                    :to="{name:'Dashboard'}"
                > zurück zum Dashboard
                </v-btn>
              </v-card-text>
              <div class="flying-img">
                <v-img
                    :src="require('@/assets/illustrations/scene-7-orders.svg')"
                    width="150px"
                    class="ma-5"
                ></v-img>
              </div>

            </div>
          </v-card>

          <v-divider class="my-6"></v-divider>


            <v-col v-if="allOrders.length < 1">
              <v-card class="card-transparent py-4">
                <v-card-text class="text-center">
                  Schade... Deine Bestellhistorie ist noch leer!
                  <v-img
                      class="mx-auto my-4"
                      :src="require('@/assets/illustrations/scene-8-sad-face.svg')"
                      width="150px"
                  ></v-img>
                </v-card-text>
              </v-card>
            </v-col>

            <v-expansion-panels>
            <v-expansion-panel
                v-for="(order,i) in allOrders"
                :key="order.id"
            >
              <v-expansion-panel-header class="card-default" height="80"
                                        >
                <div
                                      class="d-flex align-center"

                                  >

                                    <v-list-item>
                                      <v-list-item-action class="">
                                              <span class="position-number-span">
                                                  {{ i + 1 }}
                                              </span>
                                      </v-list-item-action>
                                      <v-list-item-avatar>
                                        <v-img
                                            alt="dish"
                                            height="150"
                                            :src="require('@/assets/backgrounds/small/hunger_background_04.jpg')"
                                        >
                                        </v-img>
                                      </v-list-item-avatar>

                                      <v-list-item-content class="ml-2">
                                        <v-list-item-title>{{ order.menu.title }}
                                        </v-list-item-title>

                                        <v-list-item-subtitle>
                                          {{ order.menu.restaurant.name }} •
                                          {{ order.createdAt | luxon({output : {format : "dd.MM.yyyy"} } ) }}
                                        </v-list-item-subtitle>
                                      </v-list-item-content>

                                    </v-list-item>
                                  </div>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card
                                        class="mx-auto card-default mt-3"
                                    >
                                      <template slot="progress">
                                        <v-progress-linear
                                            color="primary"
                                            height="5"
                                            indeterminate
                                        ></v-progress-linear>
                                      </template>

                                      <v-img
                                          height="150"
                                          :src="require('@/assets/backgrounds/small/hunger_background_04.jpg')"
                                          class="white--text d-flex align-center"
                                      >
                                        <v-card-subtitle class="text-center glassy mx-16 shadow--lighter" style="border-radius: 8px">
                                          Bild folgt in Kürze
                                        </v-card-subtitle>

                                      </v-img>

                                      <v-card-title class="pb-0">
                                        {{ order.menu.title }}
                                      </v-card-title>

                                      <v-card-text>
                                        {{ order.menu.description }}
                                        <v-chip-group
                                            v-if="order.menu.category && order.menu.category.length >= 1"
                                            class="py-0"
                                        >
                                          <v-chip
                                              v-for="category in order.menu.category" :key="category.id"
                                              outlined
                                              small
                                              :color='"#" + category.color'
                                              class="my-0"
                                          >
                                            <v-icon
                                                small
                                                v-if="category.icon"
                                                left>
                                              {{ 'mdi-' + category.icon }}
                                            </v-icon>
                                            {{ category.name }}
                                          </v-chip>
                                        </v-chip-group>
                                      </v-card-text>
                                      <v-card-text class="text-right text-h6">
                                        {{ order.menu.price | priceFilter }} €
                                      </v-card-text>

                                      <v-divider class="mx-4"></v-divider>

                                      <v-card-title class="pb-0">Bestellungsstatus</v-card-title>
                                      <v-list
                                          two-line
                                      >
                                        <v-list-item>
                                          <v-list-item-content>
                                            <v-list-item-title>Erstellt am</v-list-item-title>
                                            <v-list-item-subtitle>{{ order.createdAt | luxon({output : {format : "dd.MM.yyyy"} } ) }} um
                                              {{ order.createdAt | luxon({output : {format : "HH:mm"} } ) }} Uhr
                                            </v-list-item-subtitle>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                          <v-list-item-content>
                                            <v-list-item-title>Restaurant</v-list-item-title>
                                            <v-list-item-subtitle>
                                              <router-link :to="`/restaurant/${order.menu.restaurant.id}`">
                                                {{ order.menu.restaurant.name }}
                                              </router-link>
                                              <v-icon small>mdi-arrow-top-right</v-icon>
                                            </v-list-item-subtitle>
                                          </v-list-item-content>
                                        </v-list-item>
                                        <v-list-item>
                                          <v-list-item-content>
                                            <v-list-item-title>Event</v-list-item-title>
                                            <v-list-item-subtitle v-if="order.event">
                                              <router-link :to="`/event/${order.event.id}`">
                                                {{ order.event.restaurant.name }} am
                                                {{ order.event.createdAt | luxon({output : {format : "dd.MM.yyyy"} } ) }} um
                                                {{ order.event.collectTime | luxon({output : {format : "HH:mm"} } ) }} Uhr
                                              </router-link>
                                              <v-icon small>mdi-arrow-top-right</v-icon>
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle v-if="order.event && !order.event.active">
                                              <v-chip small>Abgeschlossen</v-chip>
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle v-else-if="order.event && order.event.active">
                                              <v-chip outlined color="success" small>Offen</v-chip>
                                            </v-list-item-subtitle>
                                            <v-list-item-subtitle v-else-if="!order.event">
                                              <v-chip small outlined color="error">Event gelöscht</v-chip>
                                            </v-list-item-subtitle>
                                          </v-list-item-content>
                                        </v-list-item>
                                      </v-list>

                                    </v-card>

              </v-expansion-panel-content>
            </v-expansion-panel>
            </v-expansion-panels>

        </div>

      </v-col>
<!--      events-->
      <v-col cols="12" md="6">
        <!--                  Calendar-->
        <div v-if="allEvents.length > 0">
        <v-btn
            icon
            class="ma-2"
            @click="$refs.calendar.prev()"
        >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
        <v-btn
            icon
            class="ma-2"
            @click="$refs.calendar.next()"
        >
          <v-icon>mdi-chevron-right</v-icon>
        </v-btn>
        <v-sheet height="600"
                 class="mb-6">

          <v-calendar
              ref="calendar"
              v-model="eventCalendar.value"
              type="month"
              :weekdays="eventCalendar.weekday"
              :events="hungerEvents"
              :event-overlap-threshold="30"
          ></v-calendar>
        </v-sheet>
        </div>
        <v-row>
          <v-col
              v-for="(event) in allEvents"
              :key="event.id"
              cols="12"
          >
            <v-item :value="event.id">
              <v-card
                  class="d-flex align-center card-default element-clickable"
                  height="100"
                  @click="openEventInNewTab(event.id)"
              >

                <v-list-item>
                  <v-list-item-action class="ml-2">
                            <span class="position-number-span grey--text">
                                ID: {{ event.id }}
                            </span>
                  </v-list-item-action>
                  <v-list-item-avatar>
                    <v-img
                        alt="dish"
                        height="150"
                        :src="generateRestaurantCover(event)"
                    >
                    </v-img>
                  </v-list-item-avatar>

                  <v-list-item-content class="ml-2">
                    <v-list-item-title>{{ event.restaurant.name }}
                    </v-list-item-title>

                    <v-list-item-subtitle>
                      {{ event.createdAt | luxon({output : {format : "dd.MM.yyyy"} } ) }}
                      <span v-if="event.totalAmount"> • Summe: {{ event.totalAmount | priceFilter}} €</span>
                    </v-list-item-subtitle>
                    <v-list-item-subtitle>
                      <v-chip v-if="!event.active" small>Abgeschlossen</v-chip>
                      <v-chip v-else color="success" small>Offen</v-chip>

                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <!--                          <v-list-item-action class="">-->
                  <!--                            <v-btn icon>-->
                  <!--                              <v-icon v-if="active">-->
                  <!--                                mdi-close-->
                  <!--                              </v-icon>-->
                  <!--                              <v-icon v-if="!active">-->
                  <!--                                mdi-chevron-right-->
                  <!--                              </v-icon>-->
                  <!--                            </v-btn>-->
                  <!--                          </v-list-item-action>-->

                </v-list-item>
              </v-card>
            </v-item>
          </v-col>
        </v-row>
      </v-col>
    </v-row>


  </div>
</template>

<script>
import axios from "axios";

export default {
  name : "Order",
  methods : {
    fetchOrdersFromUser() {
      axios.get(`/order/user/${this.$store.state.auth.user.id}`)
          .then((response) => {
            this.allOrders = response.data;
            this.loadingOrders = false;
          })
          .catch((e) => {
            console.error(e);
            this.$toast.error('...');
            this.loadingOrders = false;
          })
    },
    // fetchOneOrder() {
    //   this.loadingOrderDetails = true;
    //   if (this.model) {
    //     axios.get(`/order/${this.model}`)
    //         .then((response) => {
    //           this.orderDetails = response.data;
    //           this.loadingOrderDetails = false;
    //         })
    //         .catch((e) => {
    //           console.error(e);
    //           this.$toast.error('...');
    //           this.loadingOrderDetails = false;
    //         })
    //   }
    // },
    fetchAllEventsFromOneCompany() {
      this.loadingEvents = true;

      // check which Events are active
      axios.get(`/event/company/${this.$store.state.auth.user.company.id}`)
          .then((response) => {
            this.allEvents = response.data;
          })
          .catch((e) => {
            console.error(e);
            this.$toast.error('Beim Laden von Events ist etwas schiefgelaufen 😥');
          })
          .finally(() => {
            this.loadingEvents = false;
          })
    },
    generateRestaurantCover(event) {
      if (event.restaurant.coverUrl) {
        return process.env.VUE_APP_BACKEND_API_URL + event.restaurant.coverUrl;
      }
      else {
        return process.env.VUE_APP_BACKEND_API_URL + '/assets/placeholder_cover.jpg';
      }
    },
    openEventFromCalender({ event }) {
this.openEventInNewTab(event.id);
    },
    openEventInNewTab(eventId) {
      let routeData = this.$router.resolve({name: 'Event', params: {id: eventId}});
      window.open(routeData.href, '_blank');
    }
  },
  mounted() {
    this.fetchOrdersFromUser()
    this.fetchAllEventsFromOneCompany()
    // this.model = this.$route.params.id;
  },
  computed: {
    hungerEvents() {
      const events = this.allEvents
      events.forEach((element) => {
        element.start = element.createdAt.substring( 0, element.createdAt.indexOf( "T" ) );
        element.name = element.restaurant.name
      })
      return events;
  }
  },
  data() {
    return {
      loadingOrders : true,
      loadingOrderDetails : false,
      allOrders : {},
      allEvents : [],
      loadingEvents : true,
      orderDetails : {},
      model : null,
      dialog: false,
      eventCalendar: {
        value: '',
        weekday: [0, 1, 2, 3, 4, 5, 6],
      }
    }
  },
  watch : {
    model() {
      // this.$router.push({name : 'Order', params : {id : this.model}});
      // this.fetchOneOrder();
    }
  },

}
</script>

<style scoped lang="scss">
.flying-img {
  position: absolute;
  right: 0;
  bottom: 0;
}
.v-expansion-panel::before {
  content: none;
}
.v-expansion-panel-content, .v-expansion-panel-content__wrap {
  background: none !important;
}
</style>