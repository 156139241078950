<template>
    <v-card class="card-default element-clickable restaurant-card"
            :to="`restaurant/${restaurant.id}`">
        <v-img
            height="250"
            :src="generateCover()"
            dark
            class="white--text align-end"
        >
            <v-app-bar
                class="app-bar-absolute"
                flat
                color="rgba(0, 0, 0, 0)"
            >

                <v-spacer></v-spacer>
                <!--                                        <div v-if="restaurant.googleApi">-->
                <!--                                            <v-chip color="success" dark label-->
                <!--                                            >-->
                <!--                                                Geöffnet-->
                <!--                                            </v-chip>-->
                <!--                                        </div>-->
            </v-app-bar>
            <v-card-title class="text-h6 font-weight-bold text-shadow--default">{{ restaurant.name }}</v-card-title>
            <!--                                    <v-card-text>-->
            <!--                                        <v-row-->
            <!--                                            align="center"-->
            <!--                                            class="mx-0"-->
            <!--                                        >-->
            <!--                                            <v-rating-->
            <!--                                                :value="4.8"-->
            <!--                                                color="amber"-->
            <!--                                                dense-->
            <!--                                                half-increments-->
            <!--                                                readonly-->
            <!--                                                size="14"-->
            <!--                                            ></v-rating>-->

            <!--                                            <div class="grey&#45;&#45;text ms-4">-->
            <!--                                                4.8 (48)-->
            <!--                                            </div>-->
            <!--                                        </v-row>-->
            <!--                                    </v-card-text>-->

        </v-img>
        <v-card-actions
            v-if='$store.state.auth.user.roles.find(role => role === "ROLE_ADMIN")'
            class="show-on-card-hover v-btn--absolute">
            <v-btn
                fab
                x-small
                dark
                color="amber"
                @click.prevent="dialogEditRestaurant = true"
            >
                <v-icon small>
                    mdi-pencil
                </v-icon>
                <EditRestaurant
                    :dialogEdit="dialogEditRestaurant"
                    :restaurant="restaurant"
                    @dialogChanged="dialogEditRestaurant = $event;"
                    @updateRestaurants="$emit('updateRestaurants')"
                ></EditRestaurant>
            </v-btn>

            <v-btn
                fab
                x-small
                color="error"
                @click.prevent="deleteRestaurant(restaurant.id)"
            >
                <v-icon small>
                    mdi-delete
                </v-icon>
            </v-btn>

        </v-card-actions>
    </v-card>
</template>

<script>
import EditRestaurant from "./EditRestaurant.vue";
import axios from "axios";

export default {
    name: "RestaurantComponent",
    components: {
        EditRestaurant,
    },
    props: ['restaurant'],
    methods: {
        generateCover() {
            if (this.restaurant.coverUrl) {
                return process.env.VUE_APP_BACKEND_API_URL + this.restaurant.coverUrl;
            } else return process.env.VUE_APP_BACKEND_API_URL + '/assets/placeholder_cover.jpg';
        },
        deleteRestaurant($restaurantId) {
            this.loadingRemove = true;
            axios.delete(`/restaurant/${$restaurantId}`)
                .then(() => {
                    this.$toast.success(`Restaurant erfolgreich gelöscht.`);
                })
                .catch(() => {
                    this.$toast.error(`🚫 Du hast anscheinend keine Rechte, das Restaurant zu löschen.`)
                })
                .finally(() => {
                    this.$emit('updateRestaurants')
                    this.loadingRemove = false;
                })
        }
    },
    data() {
        return {
            loadingRemove: false,
            dialogCreateRestaurant: false,
            dialogEditRestaurant: false,
        }
    }
}
</script>

<style scoped lang="scss">

.app-bar-absolute {
    position: absolute;
    top: 0;
    right: 1rem;
}

.show-on-card-hover {
    top: 50%;
    transform: translateY(-50%);
}
</style>