<template>
    <div>
        <v-dialog
            @input="$emit('dialogChanged', $event)"
            :value="dialogEdit"
            width="800"
            scrollable
        >
            <v-card class="card-default">
                <v-card-title class="text-h5 font-weight-bold">
                    Speise bearbeiten
                </v-card-title>
                <v-card-subtitle>
                    Editiere eine bestehende Speise
                </v-card-subtitle>
                <v-divider></v-divider>
                <v-card-text>
                    <v-form v-model="valid" @submit.prevent="editMenu()">

                        <div class="pt-8">
                            <v-row>
                                <v-col cols="12">
                                    <v-card class="mx-auto card-default">
                                        <v-img
                                            height="150"
                                            :src="require('@/assets/backgrounds/small/hunger_background_04.jpg')"
                                            class="white--text d-flex align-center"
                                        >
                                            <v-card-subtitle class="text-center glassy mx-16 shadow--lighter"
                                                             style="border-radius: 8px">
                                                Bild folgt in Kürze
                                            </v-card-subtitle>

                                        </v-img>
                                    </v-card>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        v-model="newMenuData.title"
                                        label="Speise"
                                        placeholder="Hamburger Royal Käse"
                                        outlined
                                    >
                                    </v-text-field>
                                    <v-text-field
                                        v-model="newMenuData.description"
                                        label="Beschreibung"
                                        placeholder="Mozzarella, Tomaten, Basilikum"
                                        outlined
                                        dense
                                    >
                                    </v-text-field>
                                    <v-row>
                                        <v-col cols="6" md="4">
                                            <v-text-field
                                                v-model.number="newMenuData.points"
                                                label="Punktzahl"
                                                :rules="pointsRules"
                                                required
                                                placeholder="20"
                                                outlined
                                                dense
                                                type="number"
                                            >
                                            </v-text-field>
                                        </v-col>
                                        <v-col cols="6" md="4">
                                            <v-text-field
                                                v-model.number="newMenuData.price"
                                                :rules="required"
                                                required
                                                label="Preis"
                                                placeholder="8,50"
                                                outlined
                                                dense
                                                type="number"
                                                append-outer-icon="mdi-currency-eur"
                                            >
                                            </v-text-field>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-row>
                        </div>
                    </v-form>
                    <!--                            New Groups or Category -->

                                        <div class="d-flex justify-space-between">
                                            <div style="flex: 1">
                                                <v-card-title class="text-h6 font-weight-bold">
                                                    Gruppen
                                                </v-card-title>
                                                <v-card-subtitle>
                                                    Gruppierung der Speise bearbeiten
                                                </v-card-subtitle>
                                            </div>
                                            <v-divider vertical></v-divider>
                                            <div style="flex: 1;">
                                                <v-card-title class="text-h6 text-right font-weight-bold">
                                                    Kategorien
                                                </v-card-title>
                                                <v-card-subtitle>
                                                    Kategorien-Zuweisung bearbeiten
                                                </v-card-subtitle>
                                            </div>
                                        </div>
                                        <v-divider></v-divider>
                                        <v-row>
                                            <v-col cols="6">
                                                <v-card-text>
                                                    <v-chip-group
                                                        v-model="newMenuData.menuGroup"
                                                        column>
                                                        <v-chip
                                                            v-for="group in allGroups"
                                                            :key="group.id"
                                                            :value="group.id"
                                                            :color='"#" + group.color'
                                                            class="tiny-effect element-clickable category-badges"
                                                            active-class="v-item&#45;&#45;active dark-chip"
                                                        >
                                                            {{ group.title }}
                                                            <div
                                                                v-if='$store.state.auth.user.roles.find(role => role === "ROLE_ADMIN")'
                                                                class="show-on-card-hover v-btn&#45;&#45;absolute">
                    
                                                                <v-btn
                                                                    fab
                                                                    x-small
                                                                    color="error"
                                                                    @click.prevent="deleteGroup(group.id)"
                                                                >
                                                                    <v-icon small>
                                                                        mdi-delete
                                                                    </v-icon>
                                                                </v-btn>
                                                            </div>
                                                        </v-chip>
                                                    </v-chip-group>
                    
                                                    <div class="d-flex align-center mt-6 mb-4">
                                                        <v-divider></v-divider>
                                                        <span class="mx-2 grey&#45;&#45;text text-overline">erstelle neue Gruppe</span>
                                                        <v-divider></v-divider>
                                                    </div>
                                                    <v-card class="card-default">
                                                        <v-card-text>
                                                            <NewGroup
                                                                @fetchAllGroups="fetchAllGroups"
                                                            ></NewGroup>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-card-text>
                                            </v-col>
                                            <v-col cols="6">
                                                <v-card-text>
                                                    <v-chip-group
                                                        multiple
                                                        column
                                                        v-model="newMenuData.category"
                                                    >
                                                        <v-chip
                                                            v-for="category in allCategories" :key="category.id"
                                                            outlined
                                                            :value="category.id"
                                                            :color='"#" + category.color'
                                                            class="category-badges"
                                                        >
                                                            <v-icon
                                                                small
                                                                v-if="category.icon"
                                                                left>
                                                                {{ 'mdi-' + category.icon }}
                                                            </v-icon>
                                                            {{ category.name }}
                                                            <div
                                                                v-if='$store.state.auth.user.roles.find(role => role === "ROLE_ADMIN")'
                                                                class="show-on-card-hover v-btn&#45;&#45;absolute">
                    
                                                                <v-btn
                                                                    fab
                                                                    x-small
                                                                    color="error"
                                                                    @click.prevent="deleteCategory(category.id)"
                                                                >
                                                                    <v-icon small>
                                                                        mdi-delete
                                                                    </v-icon>
                                                                </v-btn>
                                                            </div>
                                                        </v-chip>
                                                    </v-chip-group>
                                                    <div class="d-flex align-center mt-6 mb-4">
                                                        <v-divider></v-divider>
                                                        <span class="mx-2 grey&#45;&#45;text text-overline">erstelle neue Kategorie</span>
                                                        <v-divider></v-divider>
                                                    </div>
                                                    <v-card class="card-default">
                                                        <v-card-text>
                                                            <NewCategory
                                                                @fetchAllCategories=fetchAllCategories()
                                                            ></NewCategory>
                                                        </v-card-text>
                                                    </v-card>
                                                </v-card-text>
                                            </v-col>
                                        </v-row>

                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        elevation="0"
                        @click="$emit('dialogChanged', false)"
                    >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        color="primary"
                        :disabled="loading || !valid"
                        @click="editMenu"
                    >
                        Speichern
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import axios from "axios";
import NewGroup from "../group/NewGroup.vue";
import NewCategory from "../category/NewCategory.vue";

export default {
    name: "EditMenu",
    components: {NewCategory, NewGroup},
    props: ['menu', 'dialogEdit', 'restaurantData'],
    methods: {
        editMenu() {
            this.loading = true;
            axios.put(`/menu/${this.menu.id}`, this.newMenuData)
                .then(() => {
                    this.$toast.success('Toll! Dein Lokal wurde aktualisiert');
                })
                .catch((e) => {
                    console.error(e);
                    this.$toast.error('Etwas ist schiefgelaufen. Versuche es noch einmal.');
                })
                .finally(() => {
                    this.loading = false;
                    this.$emit('updateRestaurants');
                    this.$emit('dialogChanged', false);
                })
        },
        mappingMenuData() {
            this.newMenuData.title = this.menu.title;
            this.newMenuData.description = this.menu.description;
            this.newMenuData.points = this.menu.points;
            this.newMenuData.price = this.menu.price;
            this.newMenuData.restaurant = this.menu.restaurant.id;
            this.newMenuData.category = this.menu.category;
            this.newMenuData.menuGrpup = this.menu.menuGrpup;
        },
        generateCover() {
            if (this.newMenuData.coverUrl) {
                return process.env.VUE_APP_BACKEND_API_URL + this.newMenuData.coverUrl;
            } else return process.env.VUE_APP_BACKEND_API_URL + '/assets/placeholder_cover.jpg';
        },
        fetchAllGroups() {
            this.loadingGroups = true;
            axios.get(`/group`)
                .then((response) => {
                    this.allGroups = response.data;
                })
                .catch((e) => {
                    console.error(e);
                    this.$toast.error('Fehler beim Laden von Gruppen');
                })
                .finally(() => {
                    this.loadingGroups = false;
                })
        },
        fetchAllCategories() {
            this.loadingCategories = true;
            axios.get(`/category`)
                .then((response) => {
                    this.allCategories = response.data;
                })
                .catch((e) => {
                    console.error(e);
                    this.$toast.error('Fehler beim Laden von Kategorien');
                })
                .finally(() => {
                    this.loadingCategories = false;
                })
        },
        deleteCategory($categoryId) {
            axios.delete(`/category/${$categoryId}`)
                .then(() => {
                    this.fetchAllCategories();
                    this.$toast.success(`Kategorie gelöscht.`);
                })
                .catch(() => {
                    this.$toast.error(`🚫 Du hast anscheinend keine Rechte, die Kategorie zu löschen.`)
                })
        },
        deleteGroup($groupId) {
            axios.delete(`/group/${$groupId}`)
                .then(() => {
                    this.fetchAllGroups();
                    this.$toast.success(`Gruppe gelöscht.`);
                })
                .catch(() => {
                    this.$toast.error(`🚫 Du hast anscheinend keine Rechte, die Gruppe zu löschen.`)
                })
        },
    },
    mounted() {
        this.fetchAllGroups();
        this.fetchAllCategories();
        this.mappingMenuData();
    },
    data() {
        return {
            loading: false,
            valid: false,
            newMenuData: {
                title: null,
                description: null,
                points: null,
                price: null,
                restaurant: this.restaurantData.id,
                category: [],
                menuGroup: null,
            },
            loadingGroups: false,
            allGroups: [],
            loadingCategories: false,
            allCategories: [],
            selectedGroupData: [],
            loadingSelectedGroupData: false,
            selectedCategoriesData: [],
            loadingSelectedCategoriesData: false,

            required: [
                v => !!v || 'Dies ist ein Pflichtfeld',
            ],
            pointsRules: [
                v => !!v || 'Dies ist ein Pflichtfeld',
                v => v > 0 || 'der Wert muss größer als 0 sein!'
            ],
        }
    }
}
</script>

<style scoped>
.show-on-card-hover {
    right: .25rem !important;
    top: 100%;
}
</style>