<template>
    <div>
        <v-row>
            <v-col cols="12">
                <v-card class="card-transparent">
                    <v-card-title class="text-h5 font-weight-bold">Rangliste aller Mitarbeiter von &nbsp;<span
                        class="grey--text text--darken-1">{{ $store.state.auth.user.company.name }}</span>
                    </v-card-title>
                    <v-card-subtitle>Unser Ziel ist es, wieder gemeinsam Mittagspause zu verbringen und essen zu gehen.
                        <br>Für
                        jede Bestellung bekommst du Punkte, die zeigen was für einzigartige Seele der Gesellschaft du
                        bist.
                    </v-card-subtitle>
                </v-card>
            </v-col>
        </v-row>
        <div class="loaders" v-if="loading">
            <v-row v-for="n in 10" :key="n">
                <v-col cols="12">
                    <v-skeleton-loader
                        type="table-heading"
                        class="bg-transparent"
                    ></v-skeleton-loader>
                </v-col>
            </v-row>
        </div>
        <div v-else>
            <v-row>
                <v-col cols="12" md="6">
                    <v-card class="card-default">
                        <v-list class="ranglist">
                            <template v-for="(user, index) in allUsers">
                                <v-list-item
                                    :key="user.id"
                                    v-bind:class="`position item-index-${index}`"

                                >
                                    <v-list-item-action class="ml-2">
                            <span class="position-number-span">
                                {{ index + 1 }}
                            </span>
                                    </v-list-item-action>
                                    <v-list-item-avatar>
                                        <v-img
                                            v-if="user.avatarPath"
                                            :src="avatarSrc + user.avatarPath">
                                        </v-img>
                                        <v-img
                                            v-else
                                            src="https://picsum.photos/100/100?blur=10">
                                        </v-img>
                                    </v-list-item-avatar>

                                    <v-list-item-content class="ml-2">
                                        <v-list-item-title>{{ user.firstname }} {{ user.lastname }}</v-list-item-title>

                                        <v-list-item-subtitle>{{ user.points }}</v-list-item-subtitle>
                                    </v-list-item-content>


                                </v-list-item>

                                <v-divider
                                    v-if="index < allUsers.length - 1"
                                    :key="index"
                                ></v-divider>
                            </template>
                        </v-list>
                    </v-card>
                </v-col>
            </v-row>
        </div>
    </div>
</template>
<script>
import axios from "axios";

export default {
    name: "Ranking",
    methods: {
        fetchAllUsers() {
            this.loading = true;
            axios.get(`/ranking/${this.$store.state.auth.user.company.id}`)
                .then((response) => {
                    this.allUsers = response.data;
                })
                .catch((e) => {
                    console.error(e);
                    this.$toast.error('Liste konnte nicht geladen werden. Bitte lade die Seite neu.');
                })
                .finally(() => {
                    this.loading = false;
                })

        }
    },
    data() {
        return {
            loading: true,
            allUsers: [],
            avatarSrc: process.env.VUE_APP_BACKEND_API_URL,
            files: [
                {
                    color: 'blue',
                    icon: 'mdi-clipboard-text',
                    subtitle: 'Jan 20, 2014',
                    title: 'Vacation itinerary',
                },
                {
                    color: 'amber',
                    icon: 'mdi-gesture-tap-button',
                    subtitle: 'Jan 10, 2014',
                    title: 'Kitchen remodel',
                },
            ],

        }
    },
    mounted() {
        this.fetchAllUsers();
    }
}
</script>

<style scoped lang="scss">
.ranglist {
    > .position.item-index-0,
    > .position.item-index-1,
    > .position.item-index-2 {
        .position-number-span {
            color: white;
            position: relative;
            z-index: 5;

            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                width: 28px;
                height: 40px;
                background-size: contain;
                transform: translate(-35%, -20%);
                z-index: -1;
            }
        }
    }

    > .item-index-0 {
        .position-number-span {
            &::after {
                background-image: url('../../assets/icons_badges/number_gold.svg');
            }
        }
    }

    > .item-index-1 {
        .position-number-span {
            &::after {
                background-image: url('../../assets/icons_badges/number_silver.svg');
            }
        }
    }

    > .item-index-2 {
        .position-number-span {
            &::after {
                background-image: url('../../assets/icons_badges/number_bronze.svg');
            }
        }
    }
}
</style>