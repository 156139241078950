<template>
    <v-row>
        <v-col cols="12">
            <v-card class="card-transparent">
                <v-card-title class="text-h5 font-weight-bold">Profileinstellungen</v-card-title>
                <v-card-subtitle>Bearbeite Informationen zu deinem Konto und passe die allgemeinen Einstellungen an.
                </v-card-subtitle>
            </v-card>
        </v-col>

        <v-col cols="12">
            <v-tabs vertical class="tabs-default">
                <v-tab class="tab-default">
                    <v-icon left>
                        mdi-face
                    </v-icon>
                    Allgemein
                </v-tab>
                <v-tab class="tab-default">
                    <v-icon left>
                        mdi-lock
                    </v-icon>
                    Sicherheit
                </v-tab>

                <v-tab-item>
                    <AccountSettingsAccount></AccountSettingsAccount>
                </v-tab-item>
                <v-tab-item>
                    <AccountSettingsSecurity></AccountSettingsSecurity>
                </v-tab-item>
            </v-tabs>
        </v-col>

    </v-row>
</template>

<script>
import AccountSettingsAccount from "./AccountSettingsAccount.vue";
import AccountSettingsSecurity from "./AccountSettingsSecurity.vue";

export default {
    components: {AccountSettingsAccount, AccountSettingsSecurity},
    data() {
        return {}
    }
}
</script>
