<template>
    <div class="print ">
      <div class="print-visibility print-infos">
        <h4>{{ eventData.restaurant.name }}&nbsp;am
          {{ eventData.createdAt | luxon({output : {format : "dd.MM.yyyy"} }) }}&nbsp;um
          {{ eventData.collectTime | luxon({output : {format : "HH:mm"} }) }} Uhr</h4>
      </div>
      <v-list class="card-transparent" two-line>
        <v-row>
          <v-col cols="12" class="print-hidden" v-if="!allParticipants.length > 0">
            <v-card class="card-transparent">
              <v-card-text class="text-center">
                Niemand hat sich noch eingetragen.
              </v-card-text>
            </v-card>
          </v-col>
          <v-col cols="12" md="6" class="print-hidden" v-if="eventData.active">
            <v-img
                :src="require('../../assets/illustrations/scene-7-scroll-down-to-the-form.svg')"
            >
            </v-img>
          </v-col>
          <v-col
              cols="12" md="6"
              :key="index"
              v-for="(participant, index) in allParticipants"
          >
            <participant-table-row
                :participant="participant"
                :event="eventData"
                @fetchParticipants="$emit('fetchEventData')"
            >
            </participant-table-row>
          </v-col>
        </v-row>
      </v-list>
      <v-row v-if="allParticipants.length > 0" class="print-hidden">
        <v-col cols="12" class="d-flex justify-end" style="gap: 1rem;">
          <v-btn color="primary" outlined rounded @click="printComponent">
            <v-icon left>
              mdi-printer
            </v-icon>
            Bestellungsliste drucken
          </v-btn>
          <v-tooltip top v-if="eventData.active">
            <template v-slot:activator="{ on, attrs }">
              <div v-on="on">
              <v-btn
                  color="primary"
                  outlined
                  rounded
                  v-bind="attrs"
                  disabled
                  >
                <v-icon left>
                  mdi-file-document
                </v-icon>
                Bewirtungsaufwendungen generieren
              </v-btn>
              </div>
            </template>
            <span>Du musst erstmal das gesamte Event schließen</span>
          </v-tooltip>
          <v-btn
              v-else
              color="primary"
              outlined rounded
              @click.prevent="dialogGenerateInvoice = true">
            <v-icon left>
              mdi-file-document
            </v-icon>
            Bewirtungsaufwendungen generieren
          </v-btn>
          <GenerateInvoice
              :eventData="eventData"
              :dialogGenerateInvoice="dialogGenerateInvoice"
              @dialogChanged="dialogGenerateInvoice = $event;"
          >
          </GenerateInvoice>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import ParticipantTableRow from "./ParticipantTableRow.vue";
import {Printd} from 'printd';
import GenerateInvoice from "./GenerateInvoice";

export default {
  name : "OrderListForEvent",
  components : {
    GenerateInvoice,
    ParticipantTableRow
  },
  props : ['eventData', 'allParticipants'],
  methods : {
    printComponent() {
      this.d.print(this.$el, [this.cssText]);
    },

  },
  mounted() {
    this.d = new Printd();
  },
  data() {
    return {
      dialogGenerateInvoice : false,
      cssText : `
            .participant-row {
            font-family: Arial;
            font-size: 12px;
                align-items: flex-start;
                background-color: transparent;
            } .print-hidden {
            display: none!important;
            }
            `
    }
  }

}
</script>

<style scoped lang="scss">

</style>