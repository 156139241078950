import Vue from 'vue'
import VueRouter from 'vue-router'
// Auth
import Login from "../views/Login.vue";
import Register from "../views/Register.vue";
import NewPass from "../views/NewPass.vue";
// Dashboard + App
import AccountSettings from "../views/account-settings/AccountSettings.vue";
import Dashboard from "../views/dashboard/Dashboard.vue";
import Ranking from "../views/ranking/Ranking.vue";
import AdminDashboard from "../views/admin/AdminDashboard.vue";

import store from '../store';
import Restaurant from "../views/restaurants/Restaurant.vue";
import Order from "../views/order/Order.vue";
// import OrderDashboard from "../views/order/OrderDashboard.vue";
import EventDashboard from "../views/event/EventDashboard.vue";
import RestaurantDashboard from "../views/restaurants/RestaurantDashboard.vue";
import EventInvoice from "../views/event/EventInvoice";

Vue.use(VueRouter)

// All pages in the App
const routes = [
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { auth: true }
  },
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: { auth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { auth: false }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: { auth: false }
  },
  {
    path: '/new-pass',
    name: 'NewPass',
    component: NewPass,
    meta: { auth: false }
  },
  {
    path: '/account-settings',
    name: 'AccountSettings',
    component: AccountSettings,
    meta: { auth: true }
  },
  {
    path: '/ranking',
    name: 'Ranking',
    component: Ranking,
    meta: { auth: true }
  },
  {
    path: '/restaurant',
    name: 'Restaurants',
    component: Restaurant,
    meta: { auth: true }
  },
  {
    path: '/restaurant/:id',
    name: 'Restaurant',
    component: RestaurantDashboard,
    meta: { auth: true }
  },
  // {
  //   path: '/order/:id',
  //   name: 'Order',
  //   component: Order,
  //   meta: { auth: true }
  // },
  {
    path: '/order/:id?',
    name: 'Order',
    component: Order,
    meta: { auth: true }
  },
  {
    path: '/admin',
    name: 'Admin',
    component: AdminDashboard,
    meta: { auth: true }
  },
  {
    path: '/event/:id',
    name: 'Event',
    component: EventDashboard,
    meta: { auth: true }
  },
  {
    path: '/event/:id/invoice',
    name: 'EventInvoice',
    component: EventInvoice,
    meta: { auth: true }
  }
]

const router = new VueRouter({
  routes
})

// Check Authorization for every entry
router.beforeEach((to, from, next) => {
  // meta.auth is variable in the route's settings above
  if (to.meta.auth) {
    if (!store.state.auth.user) {
      store.dispatch('logout');
      // redirect to login page
      next({ name: 'Login' })
      return;
    }
  }
  next();
})

export default router
