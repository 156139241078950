<template>
    <div>
        <v-card-title class="text-h5 font-weight-bold">
            Speise hinzufügen
        </v-card-title>
        <v-card-subtitle>
            Du kannst hier eine neue Speise zur Speisekarte, Gruppe oder Kategorie einfügen.
        </v-card-subtitle>
        <v-divider></v-divider>
        <v-card-text>
            <v-form
                v-model="valid"
                @submit.prevent="createMenu()"
            >
                <v-row class="d-flex align-center">

                    <v-col cols="12">
                        <v-card class="mx-auto card-default">
                            <v-img
                                height="150"
                                dark
                                :src="require('@/assets/backgrounds/small/hunger_background_04.jpg')"
                                class="white--text d-flex align-center px-16"
                            >
                            </v-img>
                        </v-card>
                    </v-col>
                    <v-col cols="12">
                        <v-text-field
                            v-model="newMenu.title"
                            label="Speise"
                            placeholder="Hamburger Royal Käse"
                            outlined
                        >
                            <template v-slot:append>
                                <v-chip-group
                                >
                                    <!--                                    Group's Chip-->
                                    <v-chip
                                        v-if="newMenu.menuGroup"
                                    >
                                        <v-skeleton-loader
                                            type="card"
                                            v-if="loadingSelectedGroupData"
                                        >
                                        </v-skeleton-loader>
                                        <span v-else>{{ selectedGroupData.title }}</span>
                                    </v-chip>

                                    <v-divider v-if="newMenu.category.length > 0 && newMenu.menuGroup" vertical
                                               class="custom-divider mr-3"></v-divider>
                                    <!--                                    Categories Chips-->
                                    <span
                                        v-if="newMenu.category">

                                    <v-chip
                                        v-for="selectedCategory in selectedCategoriesData"
                                        :key="selectedCategory.id"
                                        :color="'#' + selectedCategory.color"
                                        class="category-badges"
                                        outlined
                                    >
                                        <v-icon v-if="selectedCategory.icon"> mdi-{{ selectedCategory.icon }} </v-icon>
                                        <v-icon v-else>mdi-rhombus-medium</v-icon>
                                    </v-chip>

                                    </span>
                                    <v-divider v-if="newMenu.category.length > 0 || newMenu.menuGroup" vertical
                                               class="custom-divider mr-3"></v-divider>
                                    <!--                            New Groups or Category -->
                                    <v-dialog
                                        v-model="dialogGroupsAndCategories"
                                        width="800"
                                        scrollable
                                    >
                                        <template v-slot:activator="{ on, attrs }">

                                            <v-chip
                                                v-bind="attrs"
                                                v-on="on"
                                                outlined
                                                color="brown darken-3"
                                            >
                                                <v-icon
                                                    small
                                                    left>
                                                    mdi-rhombus-split
                                                </v-icon>
                                                Gruppen & Kategorien
                                            </v-chip>
                                        </template>

                                        <v-card class="card-default">
                                            <div class="d-flex justify-space-between">
                                                <div style="flex: 1">
                                                    <v-card-title class="text-h5 font-weight-bold">
                                                        Gruppen
                                                    </v-card-title>
                                                    <v-card-subtitle>
                                                        Gruppierung der Speise
                                                    </v-card-subtitle>
                                                </div>
                                                <v-divider vertical></v-divider>
                                                <div style="flex: 1;">
                                                    <v-card-title class="text-h5 text-right font-weight-bold">
                                                        Kategorien
                                                    </v-card-title>
                                                    <v-card-subtitle>
                                                        Kategorien zuweisen
                                                    </v-card-subtitle>
                                                </div>
                                            </div>
                                            <v-divider></v-divider>
                                            <v-row>
                                                <v-col cols="6">
                                                    <v-card-text>
                                                        <v-chip-group
                                                            v-if="allGroups.length"
                                                            v-model="newMenu.menuGroup"
                                                            class="group-limited-height"
                                                            column>
                                                            <v-chip
                                                                v-for="group in allGroups"
                                                                :key="group.id"
                                                                :value="group.id"
                                                                :color='"#" + group.color'
                                                                class="tiny-effect element-clickable category-badges"
                                                                active-class="v-item--active dark-chip"
                                                            >
                                                                {{ group.title }}
                                                                <div
                                                                    v-if='$store.state.auth.user.roles.find(role => role === "ROLE_ADMIN")'
                                                                    class="show-on-card-hover v-btn--absolute">

                                                                    <v-btn
                                                                        fab
                                                                        x-small
                                                                        color="error"
                                                                        @click.prevent="deleteGroup(group.id)"
                                                                    >
                                                                        <v-icon small>
                                                                            mdi-delete
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </div>
                                                            </v-chip>
                                                        </v-chip-group>

                                                        <div class="d-flex align-center mt-6 mb-4">
                                                            <v-divider></v-divider>
                                                            <span class="mx-2 grey--text text-overline">erstelle neue Gruppe</span>
                                                            <v-divider></v-divider>
                                                        </div>
                                                        <v-card class="card-default">
                                                            <v-card-text>
                                                                <NewGroup
                                                                    @fetchAllGroups="fetchAllGroups"
                                                                ></NewGroup>
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-card-text>
                                                </v-col>
                                                <v-col cols="6">
                                                    <v-card-text>
                                                        <v-chip-group
                                                            v-if="allCategories"
                                                            multiple
                                                            column
                                                            v-model="newMenu.category"
                                                        >
                                                            <v-chip
                                                                v-for="category in allCategories" :key="category.id"
                                                                outlined
                                                                :value="category.id"
                                                                :color='"#" + category.color'
                                                                class="category-badges"
                                                            >
                                                                <v-icon
                                                                    small
                                                                    v-if="category.icon"
                                                                    left>
                                                                    {{ 'mdi-' + category.icon }}
                                                                </v-icon>
                                                                {{ category.name }}
                                                                <div
                                                                    v-if='$store.state.auth.user.roles.find(role => role === "ROLE_ADMIN")'
                                                                    class="show-on-card-hover v-btn--absolute">

                                                                    <v-btn
                                                                        fab
                                                                        x-small
                                                                        color="error"
                                                                        @click.prevent="deleteCategory(category.id)"
                                                                    >
                                                                        <v-icon small>
                                                                            mdi-delete
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </div>
                                                            </v-chip>
                                                        </v-chip-group>
                                                        <div class="d-flex align-center mt-6 mb-4">
                                                            <v-divider></v-divider>
                                                            <span class="mx-2 grey--text text-overline">erstelle neue Kategorie</span>
                                                            <v-divider></v-divider>
                                                        </div>
                                                        <v-card class="card-default">
                                                            <v-card-text>
                                                                <NewCategory
                                                                    @fetchAllCategories=fetchAllCategories()
                                                                ></NewCategory>
                                                            </v-card-text>
                                                        </v-card>
                                                    </v-card-text>
                                                </v-col>
                                            </v-row>

                                            <v-divider></v-divider>

                                            <v-card-actions>
                                                <v-spacer></v-spacer>
                                                <v-btn
                                                    color="primary"
                                                    @click="dialogGroupsAndCategories = false"
                                                >
                                                    Close
                                                </v-btn>
                                            </v-card-actions>
                                        </v-card>
                                    </v-dialog>
                                </v-chip-group>
                            </template>
                        </v-text-field>
                        <v-text-field
                            v-model="newMenu.description"
                            label="Beschreibung"
                            placeholder="Mozzarella, Tomaten, Basilikum"
                            outlined
                            dense
                        >
                        </v-text-field>
                        <v-row>
                            <v-col cols="6" md="4">
                                <v-text-field
                                    v-model.number="newMenu.points"
                                    label="Punktzahl"
                                    :rules="pointsRules"
                                    required
                                    placeholder="20"
                                    outlined
                                    dense
                                    type="number"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="6" md="4">
                                <v-text-field
                                    v-model.number="newMenu.price"
                                    :rules="required"
                                    required
                                    label="Preis"
                                    placeholder="8,50"
                                    outlined
                                    dense
                                    type="number"
                                    append-outer-icon="mdi-currency-eur"
                                >
                                </v-text-field>
                            </v-col>
                            <v-col cols="12" md="4">
                                <v-btn
                                    color="primary"
                                    block
                                    :loading="loading"
                                    :disabled="loading || !valid"
                                    @click="createMenu"
                                >Hinzufügen
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-form>
        </v-card-text>
    </div>
</template>

<script>
import axios from "axios";
import NewCategory from "../category/NewCategory.vue";
import NewGroup from "../group/NewGroup.vue";

export default {
    name: "NewMenuForm",
    components: {NewCategory, NewGroup},
    props: ['restaurantData'],
    methods: {
        createMenu() {
            this.loading = true;
            axios.post('/menu', this.newMenu)
                .then(() => {
                    this.$toast.success('Erfolgreich erstellt 🥳');
                })
                .catch((e) => {
                    console.error(e);
                    this.$toast.error('Beim Anlegen ist ein Fehler aufgetreten');
                })
                .finally(() => {
                    this.valid = true;
                    this.newMenu =
                        {
                            title: null,
                            description: null,
                            points: null,
                            price: null,
                            restaurant: this.restaurantData.id,
                            category: [],
                            menuGroup: null,
                        };
                    this.valid = false;
                    this.loading = false;
                    this.$emit('createdMenu');
                })
        },
        fetchAllGroups() {
            this.loadingGroups = true;
            axios.get(`/group`)
                .then((response) => {
                    this.allGroups = response.data;
                })
                .catch((e) => {
                    console.error(e);
                    this.$toast.error('Fehler beim Laden von Gruppen');
                })
                .finally(() => {
                    this.loadingGroups = false;
                })
        },
        fetchAllCategories() {
            this.loadingCategories = true;
            axios.get(`/category`)
                .then((response) => {
                    this.allCategories = response.data;
                })
                .catch((e) => {
                    console.error(e);
                    this.$toast.error('Fehler beim Laden von Kategorien');
                })
                .finally(() => {
                    this.loadingCategories = false;
                })
        },
        fetchOneCategory(id) {
            this.loadingSelectedCategoriesData = true;
            axios.get(`/category/${id}`)
                .then((response) => {
                    this.selectedCategoriesData.push(response.data);
                })
                .catch(() => {
                    this.$toast.error(`Die Kategorie mit ID ${id} konnte nicht geladen werden`);
                })
                .finally(() => {
                    this.loadingSelectedCategoriesData = false;
                })
        },
        deleteCategory($categoryId) {
            axios.delete(`/category/${$categoryId}`)
                .then(() => {
                    this.fetchAllCategories();
                    this.$toast.success(`Kategorie gelöscht.`);
                })
                .catch(() => {
                    this.$toast.error(`🚫 Du hast anscheinend keine Rechte, die Kategorie zu löschen.`)
                })
        },
        deleteGroup($groupId) {
            axios.delete(`/group/${$groupId}`)
                .then(() => {
                    this.fetchAllGroups();
                    this.$toast.success(`Gruppe gelöscht.`);
                })
                .catch(() => {
                    this.$toast.error(`🚫 Du hast anscheinend keine Rechte, die Gruppe zu löschen.`)
                })
        },
    },
    mounted() {
        this.fetchAllGroups();
        this.fetchAllCategories();
    },
    watch: {
        dialogGroupsAndCategories() {
            if (this.newMenu.menuGroup) {
                this.loadingSelectedGroupData = true
                axios.get(`/group/${this.newMenu.menuGroup}`)
                    .then((response) => {
                        this.selectedGroupData = response.data;
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast.error('Gruppen konnten nicht vollständig geladen werden');
                    })
                    .finally(() => {
                        this.loadingSelectedGroupData = false
                    })
            }
            this.selectedCategoriesData = [];
            if (this.newMenu.category.length > 0) {
                this.newMenu.category.forEach(category =>
                    this.fetchOneCategory(category)
                );
            }
        }
    },
    data() {
        return {
            valid: false,
            loading: false,
            required: [
                v => !!v || 'Dies ist ein Pflichtfeld',
            ],
            pointsRules: [
                v => !!v || 'Dies ist ein Pflichtfeld',
                v => v > 0 || 'der Wert muss größer als 0 sein!'
            ],
            newMenu: {
                title: null,
                description: null,
                points: null,
                price: null,
                restaurant: this.restaurantData.id,
                category: [],
                menuGroup: null,
            },
            dialogGroupsAndCategories: false,
            loadingGroups: false,
            allGroups: [],
            loadingCategories: false,
            allCategories: [],
            selectedGroupData: [],
            loadingSelectedGroupData: false,
            selectedCategoriesData: [],
            loadingSelectedCategoriesData: false,
        }
    }
}
</script>

<style scoped lang="scss">
> > > .v-input__append-inner {
    margin-top: .25rem !important;
}

.show-on-card-hover {
    right: .25rem !important;
    top: 100%;
}

.dark-chip {
    background-color: #0a2e3e;
    color: #fff;
}

.custom-divider {
    width: 2px;
    max-width: unset;
}

//.group-limited-height {
//    max-height: 16rem;
//    .v-slide-group__wrapper {
//        overflow: scroll !important;
//    }
//}
</style>