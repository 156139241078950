<template>
    <div>
        <v-row class="searchbar-wrapper">
            <v-col cols="12" md="6">
                <v-text-field
                    v-model="search"
                    prepend-inner-icon="mdi-magnify"
                    placeholder="Suche in der Speisekarte"
                    outlined
                    clearable
                    hide-details
                >
                </v-text-field>
            </v-col>
        </v-row>
        <div class="loaders" v-if="loadingMenu">
            <v-row v-for="n in 4" :key="n">
                <v-col cols="12">
                    <v-skeleton-loader
                        type="table-heading"
                        class="bg-transparent"
                    ></v-skeleton-loader>
                </v-col>
                <v-col
                    v-for="n in 5" :key="n"
                    cols="6"
                    md="3"
                >
                    <v-skeleton-loader
                        type="article"
                    ></v-skeleton-loader>
                </v-col>
            </v-row>
        </div>

        <div v-else
             class="menu-groups pt-3"
             v-for="(group, index) in groups"
             :key="index"
        >


            <v-item-group v-model="selectedPosition">
                    <h2
                        v-if="group[0].menuGroup"
                        class="pb-3"
                    >{{ group[0].menuGroup.title }}
                    </h2>
                    <h2 v-else
                        class="pb-3">
                        Andere <span class="text-body-1">(ohne Kategorie)</span>
                    </h2>

                    <v-row>

                        <v-col
                            v-for="(menu) in group"
                            :key="menu.id"
                            :cols="12"
                            :md=colsSettingsMd
                        >
                            <MenuItem
                                :menu="menu"
                                :restaurant-data="restaurantData"
                            ></MenuItem>
                        </v-col>
                    </v-row>
            </v-item-group>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import _ from "lodash";
import MenuItem from "./MenuItem.vue";

export default {
    name: "MenuList",
    components: {MenuItem},
    props: ['restaurantData', 'colsSettings', 'colsSettingsMd'],
    methods: {
        fetchMenu() {
            this.loadingMenu = true;
            axios.get(`/menu/restaurant/${this.restaurantData.id}`)
                .then((response) => {
                    this.menuData = response.data;
                })
                .catch((e) => {
                    console.error(e);
                    this.$toast.error('Beim Laden von der Speisekarte ist ein Fehler aufgetreten.');
                })
                .finally(() => {
                    this.loadingMenu = false;
                })
        },

        groupBy(array, key) {
            const result = {}
            array.forEach(item => {
                if (!result[item[key]]) {
                    result[item[key]] = []
                }
                result[item[key]].push(item)
            })
            return result;
        },


    },
    computed: {
        groups() {
            return _.groupBy(this.menuDataFiltered, 'menuGroup.id')
        },
        menuDataFiltered() {
            // wenn search ist leer, return alles
            if (!this.search) {
                return this.menuData
            }
            // filter on menuData
            return this.menuData.filter(menu => menu.title.toLowerCase().includes(this.search.toLowerCase()))
        }
    },
    data() {
        return {
            menuData: [],
            loadingMenu: false,
            selectedPosition: null,
            search: '',
        }
    },
    mounted() {
        return this.fetchMenu();
    },
    watch: {
        selectedPosition() {
            this.$emit('selectedPosition', this.selectedPosition);
        }
    }
}
</script>

<style scoped lang="scss">
.searchbar-wrapper {
    position: sticky;
    top: 0;
    z-index: 33;
    background-color: transparent;

    .v-input {
        background-color: white;
    }
}

.menu-groups {

}

.show-on-card-hover {
    right: .25rem !important;
    top: 100%;
}
</style>