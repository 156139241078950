<template>
    <v-row v-if="!loading">
        <v-col cols="12">
            <v-card class="mx-auto card-default">
                <v-img
                    height="150"
                    dark
                    v-if="eventData"
                    :src="generateRestaurantCover()"
                    class="white--text align-end"
                    :class="{'filter--gray-10': !eventData.active }"
                >
                </v-img>
                <!--            Event Closed -->
                <v-card-text class="event-not-active-card-info text-center" v-if="!eventData.active">
                    <v-card-subtitle class="text-center glassy px-16 shadow--lighter"
                                     style="border-radius: 8px; display: inline-block;">
                        <v-chip color="success">Event abgeschlossen
                            <v-icon right> mdi-check-circle</v-icon>
                        </v-chip>
                    </v-card-subtitle>
                </v-card-text>

            </v-card>
        </v-col>
        <v-col cols="12">
            <v-card class="card-transparent">
                <div class="d-flex justify-space-between align-center">
                    <v-card-title class="text-h5 font-weight-bold" style="white-space: nowrap;">
                        <h3 class="text-h5 font-weight-bold">
                            <router-link v-if="eventData" :to="`/restaurant/${this.eventData.restaurant.id}`">{{
                                    eventData.restaurant.name
                                }}
                            </router-link>
                        </h3> &nbsp; um
                        {{ eventData.collectTime | luxon({output: {format: "HH:mm"} }) }}
                    </v-card-title>
                    <!--            Event Actions -->
                    <v-card-actions
                        style="flex-wrap: wrap;"
                    >
                        <!--              Close Event-->
                        <v-btn v-if="eventData.active" color="primary" text @click="dialogCloseEvent = true">
                            <v-icon left>
                                mdi-lock-check
                            </v-icon>
                            Event abschließen
                            <CloseEvent
                                :event="eventData"
                                :dialogClose="dialogCloseEvent"
                                @dialogChanged="dialogCloseEvent = $event; fetchEventData()"
                            ></CloseEvent>
                        </v-btn>
                        <!--              Delete Event-->
                        <v-btn color="red" text
                               @click="dialogDeleteEvent = true"
                               v-if='($store.state.auth.user.roles.find(role => role === "ROLE_ADMIN") || $store.state.auth.user.id === eventData.createdBy.id) && eventData.active'>
                            <v-icon left>
                                mdi-delete
                            </v-icon>
                            Event absagen
                            <DeleteEvent :event="eventData"
                                         :dialogDelete="dialogDeleteEvent"
                                         @dialogChanged="dialogDeleteEvent = $event; $router.push('/dashboard')"
                            ></DeleteEvent>
                        </v-btn>
                    </v-card-actions>
                </div>
                <v-card-subtitle>
                    <div class="mb-2">
                        Erstellt von:
                        <v-chip class="pl-1 ml-1 element-not-clickable">
                            <v-avatar color="secondary" class="mr-2">
                                <v-img :src="avatarSrc + eventData.createdBy.avatarPath"></v-img>
                            </v-avatar>
                            {{ eventData.createdBy.firstname }} {{ eventData.createdBy.lastname }}
                        </v-chip>
                    </div>
                    <div class="mb-2 d-flex align-center" style="gap: .25rem">
                        Holt
                        <div v-if="eventData.active">
                            <v-chip-group v-if="eventData.collectBy && !editableCollectFeld">
                                <v-chip
                                    class="pl-1 ml-1"
                                    @click="editableCollectFeld = !editableCollectFeld"
                                    close
                                    @click:close="resetCollectBy"
                                >
                                    <v-avatar color="secondary" class="mr-2">
                                        <v-img :src="avatarSrc + eventData.collectBy.avatarPath"></v-img>
                                    </v-avatar>
                                    {{ eventData.collectBy.firstname }} {{ eventData.collectBy.lastname }}
                                </v-chip>
                            </v-chip-group>
                            <v-select
                                v-else-if="allColleagues || editableCollectFeld"
                                :items="allColleagues"
                                item-text="firstname"
                                class="d-inline-flex"
                                item-value="id"
                                hide-details
                                outlined
                                placeholder="Wer holt ab?"
                                style="max-width: 20rem"
                                dense
                                v-model="eventNewData.collectBy"
                                @change="updateEvent"
                                v-click-outside="clickOutsideEditableFeld"
                            >
                                <template slot="append">
                                    <span class="text-body-2 success--text">+10 Punkten</span>
                                </template>
                                <template v-slot:selection="{ item }">
                                    <v-chip class="pl-1"
                                    >
                                        <v-avatar color="secondary" class="mr-2">
                                            <v-img :src="avatarSrc + item.avatarPath"></v-img>
                                        </v-avatar>
                                        {{ item.firstname }} {{ item.lastname }}
                                    </v-chip>
                                </template>
                                <template v-slot:item="{ item }">
                                    <v-chip class="pl-1"
                                    >
                                        <v-avatar color="secondary" class="mr-2">
                                            <v-img :src="avatarSrc + item.avatarPath"></v-img>
                                        </v-avatar>
                                        {{ item.firstname }} {{ item.lastname }}
                                    </v-chip>
                                </template>
                            </v-select>
                        </div>
                        <div v-else class="ml-1 element-not-clickable">
                            <v-chip-group>
                                <v-chip
                                    v-if="eventData.collectBy"
                                    class="pl-1 ml-1"
                                >
                                    <v-avatar color="secondary" class="mr-2">
                                        <v-img :src="avatarSrc + eventData.collectBy.avatarPath"></v-img>
                                    </v-avatar>
                                    {{ eventData.collectBy.firstname }} {{ eventData.collectBy.lastname }}
                                </v-chip>
                                <v-chip v-else>
                                    niemand
                                </v-chip>
                            </v-chip-group>
                        </div>
                        ab, um {{ eventData.collectTime | luxon({output: {format: "HH:mm"} }) }} Uhr
                    </div>
                    <div class="mb-2">
                        Zahlt:
                        <v-chip v-if="eventData.payBy" class="pl-1 ml-1">
                            <v-avatar color="secondary" class="mr-2">AV</v-avatar>
                            {{ eventData.payBy.firstname }} {{ eventData.payBy.lastname }}
                        </v-chip>
                        <v-chip v-else class="ml-1 element-not-clickable">
                            Geschäftsführung
                        </v-chip>
                    </div>
                </v-card-subtitle>
            </v-card>
        </v-col>
        <!--      Order List-->
        <v-col cols="12">
            <v-card class="card-transparent">
                <v-card-title class="text-h5 font-weight-bold">
                    Bestellungsliste
                </v-card-title>
                <div>
                    <div class="loaders" v-if="loadingParticipants">
                        <v-row>
                            <v-col
                                v-for="n in 3" :key="n"
                                cols="6"
                                md="6"
                            >
                                <v-skeleton-loader
                                    type="list-item-avatar, list-item-three-line"
                                ></v-skeleton-loader>
                            </v-col>
                        </v-row>
                    </div>
                    <OrderListForEvent
                        v-else
                        :allParticipants="allParticipants"
                        :event-data="eventData"
                        @fetchEventData="fetchEventData()"
                        :key="orderListKey"
                    >
                    </OrderListForEvent>
                </div>
            </v-card>
        </v-col>
        <!--        New Order -->
        <v-col cols="12" v-if="eventData.active">
            <v-card class="card-default">
                <v-card-title class="text-h5 font-weight-bold">
                    Lege deine Bestellung an
                </v-card-title>
                <v-card-subtitle>
                </v-card-subtitle>
                <v-divider></v-divider>
                <new-order-form
                    :eventProp="eventData"
                    @OrderCreated="updateEventData()"
                ></new-order-form>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
import axios from "axios";
import NewOrderForm from "../../components/order/NewOrderForm.vue";
import OrderListForEvent from "../../components/event/OrderListForEvent.vue";
import DeleteEvent from "../../components/event/DeleteEvent.vue";
import CloseEvent from "../../components/event/CloseEvent.vue";

export default {
    name: "EventDashboard",
    components: {
        CloseEvent,
        DeleteEvent,
        NewOrderForm,
        OrderListForEvent
    },
    methods: {
        fetchEventData() {
            axios.get(`/event/${this.$route.params.id}`)
                .then((response) => {
                    this.eventData = response.data;
                    if (this.eventData.collectBy) {
                        this.eventNewData.collectBy = this.eventData.collectBy;
                    }
                    this.fetchParticipantsFromEvent();
                })
                .catch((e) => {
                    console.error(e);
                    this.$toast.error('Beim Laden der Event-Informationen ist ein Fehler aufgetreten.');
                })
                .finally(() => {
                    this.loading = false;
                })
        },
        generateRestaurantCover() {
            if (this.eventData) {
                if (this.eventData.restaurant.coverUrl) {
                    return process.env.VUE_APP_BACKEND_API_URL + this.eventData.restaurant.coverUrl;
                } else {
                    return process.env.VUE_APP_BACKEND_API_URL + '/assets/placeholder_cover.jpg';
                }
            }

        },
        fetchColleagues() {
            this.loadingColleagues = true;
            axios.get(`/user/company/${this.$store.state.auth.user.company.id}`)
                .then((response) => {
                    this.allColleagues = response.data;
                })
                .catch((e) => {
                    console.error(e);
                    this.$toast.error('Beim Laden von deinen Kollegen ist ein Fehler aufgetreten');
                })
            this.loadingColleagues = false;
        },
        clickOutsideEditableFeld() {
            if (this.editableCollectFeld === true) {
                this.editableCollectFeld = false;
            }
        },
        updateEventData() {
            this.fetchParticipantsFromEvent();
            this.orderListKey += 1;
        },
        updateEvent() {
            axios.put(`/event/${this.eventData.id}`, this.eventNewData)
                .then(() => {
                    console.log('success');
                    this.$toast.success('Dieses Event wurde aktualisiert! 😊');
                    this.editableCollectFeld = false;
                    this.fetchEventData();
                })
                .catch((e) => {
                    console.error(e);
                    this.$toast.error('Es ist etwas schiefgelaufen...');
                })
        },
        resetCollectBy() {
            this.eventNewData.collectBy = null;
            this.updateEvent();
        },
        fetchParticipantsFromEvent() {
            this.loadingParticipants = true;
            axios.get(`/event/${this.eventData.id}/participants`)
                .then((response) => {
                    this.allParticipants = response.data;
                })
                .catch((e) => {
                    console.error(e);
                    this.$toast.error('Teilnehmer des Events konnte nicht geladen werden.');
                })
                .finally(() => {
                    this.loadingParticipants = false;
                })
        },
    },
    data() {
        return {
            loading: true,
            eventData: null,
            avatarSrc: process.env.VUE_APP_BACKEND_API_URL,
            loadingParticipants: true,
            allParticipants: [],
            loadingColleagues: false,
            allColleagues: [],
            eventNewData: {
                collectBy: null,
            },
            orderListKey: 0,
            editableCollectFeld: false,
            editablePayFeld: false,
            // Dialogs
            dialogDeleteEvent: false,
            dialogCloseEvent: false,
        }
    },
    mounted() {
        this.fetchEventData();
        this.fetchColleagues();
    }
}
</script>

<style scoped lang="scss">

.event-not-active-card-info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
</style>