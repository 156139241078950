<template>
    <v-card class="card-transparent">
        <v-card-title class="text-h5 font-weight-bold">
            <span @mouseover="generateGreeting()" @click="generateGreeting">{{greeting }}</span>
            &nbsp;{{ $store.state.auth.user.firstname }} 👋
        </v-card-title>
        <v-card-subtitle>Hier findest du Treffpunkte, die <strong>heute</strong> innerhalb der
            <strong>{{ $store.state.auth.user.company.name }} </strong> stattfinden.
        </v-card-subtitle>
    </v-card>
</template>

<script>
export default {
    name: "DashboardWelcomeCard",
    methods: {
        generateGreeting() {
            const chosenNumber = Math.floor(Math.random() * this.greetingsList.length);
            this.greeting = this.greetingsList[chosenNumber];
        }
    },
    data() {
        return {
            greeting: 'Hallo',
            greetingsList: ['Grüezi', 'Cześć', 'Привіт', 'Hola', '여보세요', '你好', 'Hello','Hallo'],
        }
    }
}
</script>

<style scoped>

</style>