<template>
    <div class="auth-wrapper auth-v1 bg-default">
        <div class="auth-inner">
            <v-card class="mr-auto ml-auto auth-card card-default" max-width="900px">

                <v-img
                    :lazy-src="require('../assets/backgrounds/small/hunger_background_04.jpg')"
                    :src="require('../assets/backgrounds/hunger_background_04.jpg')"
                    class="auth-img"
                >
                    <template v-slot:placeholder>
                        <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                        >
                            <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                            ></v-progress-circular>
                        </v-row>
                    </template>
                    <!--              EIKONA Logo-->
                    <v-img
                        class="eikona-logo ma-6"
                        width="100px"
                        :src="require('../assets/logo/eikona-logo_white.svg')"
                    ></v-img>

                </v-img>
                <!-- title -->
                <div class="card-content pa-6">
                    <v-card-text>
                        <p class="text-h4 font-weight-bold text--primary mb-2">
                            Neues Passwort
                        </p>
                        <p class="mb-2">
                            Nur noch ein Schritt zu deinem Konto - gib ein neues Passwort <span>für
                            <v-chip small label >{{ forgotPassData.user.email }}</v-chip>
                            </span>
                            ein.
                        </p>
                    </v-card-text>

                    <!-- form -->
                    <v-card-text>
                        <v-form
                            v-model="passValid"
                            @submit.prevent="setNewPassword()"
                        >

                            <v-text-field
                                id="password"
                                label="Passwort"
                                placeholder="············"
                                name="password"
                                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="passwordRules"
                                required
                                :type="showPass ? 'text' : 'password'"
                                @click:append="showPass = !showPass"
                                autocomplete="off"
                                outlined
                                v-model.lazy="userData.password">
                            </v-text-field>

                            <!--            Password Match-->

                            <v-text-field
                                id="matched-password"
                                label="Passwort wiederholen"
                                placeholder="············"
                                name="matchedPassword"
                                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                                required
                                :type="showPass ? 'text' : 'password'"
                                @click:append="showPass = !showPass"
                                autocomplete="off"
                                outlined
                                :rules="passwordMatched"
                                v-model.lazy="checkPassword">
                            </v-text-field>


                            <v-btn
                                type="submit"
                                block
                                :loading="loading"
                                :disabled="loading || !passValid || loadingData"
                                color="primary">
                                Setze dein Passwort zurück
                            </v-btn>
                        </v-form>

                    </v-card-text>

                    <!-- create new account  -->
                    <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            Hast du bereits ein Konto?
          </span>
                        <router-link :to="{name:'Login'}">
                            Zu Anmeldung
                        </router-link>
                    </v-card-text>

                </div>


            </v-card>
            <!-- divider -->
            <v-card-text class="grey--text text-center mt-12">
                made with 🤍 in Volkach & Würzburg
            </v-card-text>
        </div>

    </div>
</template>

<script>

import axios from "axios";

export default {
    name: 'NewPass',
    data() {
        return {
            checkPassword: null,
            loading: false,
            newPassConfirmed: false,
            passValid: false,
            passwordRules: [
                v => !!v || 'Dies ist ein Pflichtfeld',
                v => (v && v.length >= 8) || 'Passwort muss mindestens 8 Zeichen beinhalten',
            ],
            passwordMatched: [
                v => !!v || 'Dies ist ein Pflichtfeld',
                v => (v === this.userData.password) || 'Passwörter stimmen nicht überein',
                v => (v && v.length >= 8) || 'Passwort muss mindestens 8 Zeichen beinhalten',
            ],
            showPass: false,
            forgotPassData: [],
            userData: {
                resetCode: this.$route.query.resetCode,
                password: null,
            },
            loadingData: false
        }
    },
    methods: {
        setNewPassword() {
            axios.post(`/reset_passwort`, this.userData)
                .then(() => {
                    this.$toast.success(`Hell yeah 🤘 Dein Passwort wurde aktualisiert. Willkommen zurück ${this.forgotPassData.user.firstname}`, {timeout: 12000});
                    this.login();
                })
                .catch((err) => {
                    console.error(err);
                    this.$toast.error('Dein Aktivierungslink ist leider nicht mehr gültig');
                })
        },
        getForgotPassData() {
                this.loadingData = true;
                    axios.get(`/forgot_passwort/code/${this.userData.resetCode}`)
                        .then((response) => {
                            this.forgotPassData = response.data;
                            this.loadingData = false
                        })
                        .catch((e) => {
                            console.error(e);
                            this.$toast.error('Daten konnten nicht geladen werden');
                        })
        },
        isTokenLocalStorage() {
            if (localStorage.getItem('token') !== null) {
                axios.get(`/user/${this.userId}`)
                    .then(() => {
                        this.$router.push('/');
                    })
                    .catch(() => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('userId');
                        this.$toast.error('Benutzer ist nicht authentifiziert');
                    })
            }
        },
        async login() {
            this.loading = true;
            try {
                await this.$store.dispatch('login', {
                    username: this.forgotPassData.user.email,
                    password: this.userData.password
                })
                await this.$router.push('/')
            } catch (err) {
                console.error(err);
                this.error = err.detail;
                this.$toast.error('Anmeldung fehlgeschlagen');
            }
            this.loading = false;
        },
    },
    mounted() {
        this.isTokenLocalStorage();
        this.getForgotPassData();
    },
}
</script>

<style lang="scss">

.auth-wrapper {
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 1.5rem;
    display: flex;
    min-height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    flex-basis: 100%;
}

// Card
.auth-card {
    display: flex;
    flex-flow: row nowrap;
    z-index: 3;

    .auth-img {
        flex: 1;
        border-top-left-radius: inherit;
        border-top-right-radius: 0 !important;
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: 0;

        // Eikona Logo
        .eikona-logo {
            bottom: 0;
            position: absolute;
        }
    }

    .card-content {
        flex: 1;
    }

    @media only screen and (max-width: 960px) {
        display: flex;
        flex-flow: column nowrap;
        .auth-img {
            max-height: 10rem;
        }
    }
}

// Backgrounds & details
.auth-mask-bg {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1;
}

.auth-tree, .auth-tree-3 {
    position: absolute;
}

.auth-tree {
    bottom: 0;
    right: 0;
}

.auth-tree-3 {
    bottom: 0;
    left: 0;
}
</style>
