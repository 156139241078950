<template>
    <div>
        <v-dialog
            @input="$emit('dialogChanged', $event)"
            :value="dialogCreate"
            width="800"
            scrollable
        >
            <v-card class="card-default">
                <v-card-title class="text-h5 font-weight-bold">
                    Worauf hast Du heute Hunger?
                </v-card-title>
                <v-card-subtitle>
                    Wähle das passendes Restaurant aus und... lass es dir schmecken!
                </v-card-subtitle>
                <v-divider></v-divider>
                <v-card-text>
                    <v-form v-model="valid" >

                        <!--                    Restaurant select-->
                        <div class="pt-8">
                            <v-item-group
                                v-model="newEventData.restaurant"
                                mandatory
                            >
                                <v-row>
                                    <v-col
                                        v-for="(restaurant) in restaurants"
                                        :key="restaurant.id"
                                        cols="6"
                                    >
                                        <v-item :value="restaurant.id" v-slot="{ active, toggle }" class="element-clickable">
                                            <v-img
                                                :src="generateRestaurantCover(restaurant)"
                                                height="150"
                                                style="border-radius: 4px"
                                                @click="toggle"
                                                dark
                                                class="pa-2"
                                            >
                                                <v-btn
                                                    icon
                                                    dark
                                                >
                                                    <v-icon>
                                                        {{
                                                            active ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'
                                                        }}
                                                    </v-icon>
                                                </v-btn>
                                                <v-card-title class="absolute-details text-h6 font-weight-bold">
                                                    {{ restaurant.name }}
                                                </v-card-title>
                                            </v-img>
                                        </v-item>
                                    </v-col>
                                </v-row>
                            </v-item-group>
                        </div>
                        <!--                                          Divider -->
                        <div class="d-flex align-center mt-6 mb-4">
                            <v-divider></v-divider>
                            <span class="mx-2 grey--text text-overline">zusätzliche Informationen</span>
                            <v-divider></v-divider>
                        </div>

                        <!--                    Hour-->
                        <div class="text-center">
                            <v-row>
                                <v-col cols="12" sm="6">
                                    <v-menu
                                        ref="collectTime"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        :return-value.sync="newEventData.collectTime"
                                        transition="scale-transition"
                                        offset-y
                                        max-width="290px"
                                        min-width="290px"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                v-model="newEventData.collectTime"
                                                outlined
                                                label="Abholungs- / Reservierungszeit *"
                                                prepend-icon="mdi-clock-time-two-outline"
                                                hint="Öffnungszeiten heute: 11:00 - 19:30"
                                                persistent-hint
                                                readonly
                                                :rules="[rules.required]"
                                                v-bind="attrs"
                                                v-on="on"
                                            ></v-text-field>
                                        </template>
                                        <v-time-picker
                                            v-model="newEventData.collectTime"
                                            full-width
                                            format="24hr"
                                            :allowed-minutes="allowedStep"
                                            scrollable
                                            @click:minute="$refs.collectTime.save(newEventData.collectTime)"
                                        ></v-time-picker>
                                    </v-menu>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-select
                                        :items="allUsers"
                                        label="Erstellt von"
                                        item-text="firstname"
                                        item-value="id"
                                        readonly
                                        outlined
                                        v-model="newEventData.createdBy"
                                    >
                                        <template slot="append">
                                            <span class="text-body-2 success--text">+10 Punkten</span>
                                        </template>
                                        <template v-slot:selection="{ item }">
                                            <v-chip class="pl-1"
                                            >
                                                <v-avatar color="secondary" class="mr-2"><v-img :src="avatarSrc"></v-img></v-avatar>
                                                {{ item.firstname }} {{ item.lastname }}
                                            </v-chip>
                                        </template>
                                        <template v-slot:item="{ item }">
                                            <v-chip class="pl-1"
                                            >
                                                <v-avatar color="pink" class="mr-2">AV</v-avatar>
                                                {{ item.firstname }} {{ item.lastname }}
                                            </v-chip>
                                        </template>
                                    </v-select>
                                </v-col>
                            </v-row>
                        </div>

                    </v-form>
                </v-card-text>
                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        elevation="0"
                        @click="$emit('dialogChanged', false)"
                    >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        color="primary"
                        :disabled="loading || !valid"
                        @click="createEvent()"
                    >
                        Speichern
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar></v-snackbar>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "newEvent",
    props: ['event', 'dialogCreate'],
    methods: {
        allowedStep: m => m % 5 === 0,
        fetchRestaurants() {
            this.loadingRestaurants = true;
            axios.get(`/restaurant`)
                .then((response) => {
                    this.restaurants = response.data;
                })
                .catch((e) => {
                    console.error(e);
                    this.$toast.error('Nicht alle Restaurants konnten geladen werden');
                })
            this.loadingRestaurants = false;
        },
        fetchColleagues() {
            this.loadingColleagues = true;
            axios.get(`/user/company/${this.$store.state.auth.user.company.id}`)
                .then((response) => {
                    this.allUsers = response.data;
                })
                .catch((e) => {
                    console.error(e);
                    this.$toast.error('Beim Laden der Liste aller Mitarbeiter ist ein Fehler aufgetreten');
                })
            this.loadingColleagues = false;
        },
        createEvent() {
            axios.post('/event', this.newEventData)
                .then(() => {
                    this.$toast.success('Erfolgreich veröffentlicht! 🎉');
                })
                .catch((e) => {
                    console.error(e);
                    this.$toast.error(`Die Veranstaltung konnte nicht erstellt werden.`);
                })
              .finally(() => {
                  this.newEventData.collectTime = null;
                  this.newEventData.restaurant = null;
                  this.$emit('dialogChanged', false);
              })
        },
        generateRestaurantCover(restaurant) {
            if (restaurant.coverUrl) {
                return process.env.VUE_APP_BACKEND_API_URL + restaurant.coverUrl;
            } else return process.env.VUE_APP_BACKEND_API_URL + '/assets/placeholder_cover.jpg';
        }
    },
    mounted() {
        this.fetchRestaurants();
        this.fetchColleagues();
    },
    data() {
        return {
            loadingRestaurants: false,
            loadingColleagues: false,
            restaurants: [],
            loading: false,
            actualStep: 1,
            newEventData: {
                collectTime: null,
                restaurant: null,
                createdBy: this.$store.state.auth.user.id,
            },
            allUsers: [

            ],
            avatarSrc: process.env.VUE_APP_BACKEND_API_URL + this.$store.state.auth.user.avatarPath,
            valid: false,
            rules: {
                required: value => !!value || 'Dies ist ein Pflichtfeld.',
            }
        }
    },
}
</script>

<style scoped lang="scss">
.absolute-details {
    position: absolute;
    bottom: 0;
    padding: 12px;
}
</style>