<template>
    <v-card class="card-default">
        <v-card-text>
            <v-form
                v-model="passValid"
                @submit.prevent="setNewPassword()"
            >
                <v-container>

                    <v-text-field
                        id="password"
                        label="Passwort"
                        placeholder="············"
                        name="password"
                        :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                        :rules="passwordRules"
                        required
                        :type="showPass ? 'text' : 'password'"
                        @click:append="showPass = !showPass"
                        autocomplete="off"
                        outlined
                        v-model.lazy="userData.newPassword">
                    </v-text-field>

                    <!--            Password Match-->

                    <v-text-field
                        id="matched-password"
                        label="Passwort wiederholen"
                        placeholder="············"
                        name="matchedPassword"
                        :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                        required
                        :type="showPass ? 'text' : 'password'"
                        @click:append="showPass = !showPass"
                        autocomplete="off"
                        outlined
                        :rules="passwordMatched"
                        v-model.lazy="checkPassword">
                    </v-text-field>

                </v-container>
                <v-card-actions>

                    <v-spacer></v-spacer>
                    <v-btn
                        type="submit"
                        :loading="loading"
                        :disabled="loading || !passValid"
                        color="primary">
                        Passwort aktualisieren</v-btn>
                </v-card-actions>

            </v-form>
            <v-container>
                <v-row>
                    <v-col cols="12">
                        <v-alert
                            outlined
                            type="error"
                            prominent
                            border="left"
                        >
                            <span>Danger Zone</span>
                            <v-divider class="my-3"></v-divider>
                            <v-row align="center">
                                <v-col class="text-body-2">
                                    Wenn du dein Konto löschst, werden die Informationen und alle damit verbundenen Bestellungen, einschließlich der Punkte und Rechnungen, gelöscht.
                                    <br><br>Gelöschte Accounts können nicht wiederhergestellt werden!
                                </v-col>
                                <v-col class="shrink">
                                    <v-btn color="error">konto löschen</v-btn>
                                </v-col>
                            </v-row>
                        </v-alert>
                    </v-col>
                </v-row>
            </v-container>
        </v-card-text>
    </v-card>
</template>

<script>
export default {
    name: 'AccountSettingsSecurity',
    methods: {
        setNewPassword() {
            return null
        },
    },
    data() {
        return {
            checkPassword: null,
            loading: false,
            newPassConfirmed: false,
            passValid: false,
            passwordRules: [
                v => !!v || 'Dies ist ein Pflichtfeld',
                v => (v && v.length >= 8) || 'Passwort muss mindestens 8 Zeichen beinhalten',
            ],
            passwordMatched: [
                v => !!v || 'Dies ist ein Pflichtfeld',
                v => (v === this.userData.newPassword) || 'Passwörter stimmen nicht überein',
                v => (v && v.length >= 8) || 'Passwort muss mindestens 8 Zeichen beinhalten',
            ],
            showPass: false,
            userData: {
                resetCode: this.$route.query.resetCode,
                newPassword: null,
            }
        }
    }
}
</script>
