<template>
    <div class="auth-wrapper auth-v1 bg-default">
        <div class="auth-inner">
            <v-card class="mr-auto ml-auto auth-card card-default" max-width="900px">

                <v-img
                    :lazy-src="require('../assets/backgrounds/small/hunger_background_03.jpg')"
                    :src="require('../assets/backgrounds/hunger_background_03.jpg')"
                    class="auth-img"
                >
                    <template v-slot:placeholder>
                        <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                        >
                            <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                            ></v-progress-circular>
                        </v-row>
                    </template>
                    <!--              EIKONA Logo-->
                    <v-img
                        class="eikona-logo ma-6"
                        width="100px"
                        :src="require('../assets/logo/eikona-logo_white.svg')"
                    ></v-img>

                </v-img>
                <!-- title -->
                <div class="card-content pa-6">
                    <v-card-text>
                        <p class="text-h4 font-weight-bold text--primary mb-2">
                            #gemainsam ist es schöner 😊
                        </p>
                        <p class="mb-2">
                            Iss mit deinen Arbeitskollegen in den besten Restaurants in Volkach
                        </p>
                    </v-card-text>

                    <!-- registration form - Stepper -->

                    <v-form
                        v-model="valid"
                        @submit.prevent="registration()"
                    >
                        <v-stepper
                            v-model="actualStep"
                            vertical
                            elevation="0"
                            class="pb-2"
                        >
                            <!--                                Step 1-->
                            <v-stepper-step
                                :complete="actualStep > 1"
                                step="1"
                            >
                                Zugangsdaten
                            </v-stepper-step>

                            <v-stepper-content step="1">
                                <v-text-field
                                    outlined
                                    id="username"
                                    :rules="emailRules"
                                    label="Email"
                                    placeholder="hunger@example.de"
                                    name="username"
                                    required
                                    v-model="userData.email"></v-text-field>


                                <v-text-field
                                    outlined
                                    id="password"
                                    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                                    :rules="required"
                                    :type="showPass ? 'text' : 'password'"
                                    label="Password"
                                    placeholder="············"
                                    name="password"
                                    @click:append="showPass = !showPass"
                                    required
                                    v-model="userData.password">
                                </v-text-field>

                            </v-stepper-content>

                            <!--                                Step 2 -->
                            <v-stepper-step
                                :complete="actualStep > 2"
                                step="2"
                            >
                                Informationen
                            </v-stepper-step>

                            <v-stepper-content step="2" class="auth-text">
                                Hi! 👋 ich heiße
                                <v-text-field
                                    v-model="userData.firstname"
                                    single-line
                                    dense
                                    class="inline-input tiny-input"
                                    :rules="required"
                                    placeholder="Reiner"
                                    required
                                    hide-details
                                ></v-text-field>
                                <v-text-field
                                    v-model="userData.lastname"
                                    single-line
                                    dense
                                    class="inline-input tiny-input"
                                    :rules="required"
                                    placeholder="Zufall"
                                    required
                                    hide-details
                                ></v-text-field>
                                und bin ein
                                <v-select
                                    :items="allUserRoles"
                                    item-text="name"
                                    item-value="id"
                                    v-model="userData.userRole"
                                    dense
                                    class="inline-input"
                                    :rules="required"
                                    required
                                    small-chips
                                    hide-details
                                ></v-select>
                                in
                                <v-select
                                    :items="allCompanies"
                                    item-text="name"
                                    item-value="id"
                                    v-model="userData.company"
                                    dense
                                    class="inline-input"
                                    :rules="required"
                                    required
                                    small-chips
                                    hide-details
                                ></v-select>
                            </v-stepper-content>

                            <!--                                Step 3 -->
                            <v-stepper-step
                                :complete="actualStep > 3"
                                step="3"
                            >
                                Lächeln bitte
                                <small>Optional</small>
                            </v-stepper-step>

                            <v-stepper-content step="3" class="">
                                <v-avatar size="150">
                                    <v-img
                                        :src="require(`@/assets/avatars/avatar_0${userData.selectedAvatar + 1}.svg`)">
                                    </v-img>
                                </v-avatar>
                                <!--                            New Profile image -->
                                <v-dialog
                                    v-model="avatarDialog"
                                    width="800"
                                    scrollable
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn
                                            class="ml-2"
                                            elevation="0"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            Profilbild ändern
                                        </v-btn>
                                    </template>

                                    <v-card class="card-default">
                                        <v-card-title class="text-h5 font-weight-bold">
                                            Profilbild ändern
                                        </v-card-title>
                                        <v-card-subtitle>
                                            Lade dein eigenes Profilbild hoch oder wähle eins aus der Galerie aus.
                                        </v-card-subtitle>
                                        <v-divider></v-divider>
                                        <v-card-text style="height: 400px">
                                            <div class="text-center pt-12 pb-8 px-16">
                                                <v-file-input
                                                    v-model="userData.avatar"
                                                    :rules="avatarRules"
                                                    accept="image/png, image/jpeg, image/svg"
                                                    outlined
                                                    class="mx-16"
                                                    placeholder="Wähle einen Avatar"
                                                    prepend-icon="mdi-camera"
                                                    label="Avatar"
                                                    show-size
                                                    persistent-hint
                                                    hint="Folgende Bilddateien sind erlaubt: jpg, png, svg. Die Bildgröße darf nicht 2MB überschreiten."
                                                ></v-file-input>

                                            </div>

                                            <!--                                          Divider -->
                                            <div class="d-flex align-center">
                                                <v-divider></v-divider>
                                                <span class="mx-2 grey--text text-overline">oder</span>
                                                <v-divider></v-divider>
                                            </div>

                                            <!--                                        Avatars select-->
                                            <div>

                                                <v-item-group
                                                    v-model="userData.selectedAvatar"
                                                    v-bind:class="{'opacity-50': userData.avatar }"
                                                >
                                                    <v-row>
                                                        <v-col
                                                            v-for="(item, i) in 8"
                                                            :key="i"
                                                            cols="6"
                                                            md="4"
                                                            lg="3"
                                                        >
                                                            <v-item v-slot="{ active, toggle }">
                                                                <v-img
                                                                    :src="require(`@/assets/avatars/avatar_0${i+1}.svg`)"
                                                                    height="150"
                                                                    style="border-radius: 4px"
                                                                    class="pa-2"
                                                                    @click="toggle"
                                                                >
                                                                    <v-btn
                                                                        icon
                                                                        dark
                                                                    >
                                                                        <v-icon>
                                                                            {{
                                                                                active ? 'mdi-checkbox-marked' : 'mdi-checkbox-blank-outline'
                                                                            }}
                                                                        </v-icon>
                                                                    </v-btn>
                                                                </v-img>
                                                            </v-item>
                                                        </v-col>
                                                    </v-row>
                                                </v-item-group>
                                            </div>
                                        </v-card-text>
                                        <v-divider></v-divider>

                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                            <v-btn
                                                elevation="0"
                                                @click="avatarDialog = false"
                                            >
                                                Abbrechen
                                            </v-btn>
                                            <v-btn
                                                color="primary"
                                                @click="avatarDialog = false"
                                            >
                                                Speichern
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </v-dialog>
                            </v-stepper-content>

                        </v-stepper>

                        <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn v-if="actualStep > 1" elevation="0" @click.prevent="actualStep = actualStep - 1">
                                Zurück
                            </v-btn>
                            <v-btn
                                color="primary"
                                @click.prevent="actualStep = actualStep + 1"
                                v-if="actualStep < 3"
                            >
                                Weiter
                                <v-icon right>
                                    mdi-chevron-right
                                </v-icon>
                            </v-btn>
                            <v-btn
                                color="primary"
                                v-else
                                type="submit"
                                :loading="loading"
                                :disabled="loading || !valid || actualStep !== 3"
                            >
                                Registrieren
                            </v-btn>
                        </v-card-actions>


                    </v-form>

                    <!-- create new account  -->
                    <v-divider class="mt-6"></v-divider>
                    <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            Hast du bereits ein Konto
          </span>
                        <router-link :to="{name:'Login'}">
                            Zur Anmeldung
                        </router-link>
                    </v-card-text>

                </div>


            </v-card>
            <!-- divider -->
            <v-card-text class="grey--text text-center mt-12">
                made with 🤍 in Volkach & Würzburg
            </v-card-text>
        </div>


        <!--    &lt;!&ndash; background triangle shape  &ndash;&gt;
            <v-img
              class="auth-mask-bg"
              :src="require('../assets/backgrounds/wave_01.svg')"
            ></v-img>

            &lt;!&ndash; tree &ndash;&gt;
            <v-img
              class="auth-tree"
              width="247"
              height="185"
              src='https://picsum.photos/200/300'
            ></v-img>

            &lt;!&ndash; tree  &ndash;&gt;
            <v-img
              class="auth-tree-3"
              width="377"
              height="289"
              src='https://picsum.photos/200/300'
            ></v-img>-->
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: 'Register',
    data() {
        return {
            avatarDialog: false,
            valid: false,
            loading: false,
            userData: {
                email: null,
                password: null,
                firstname: null,
                lastname: null,
                company: 1,
                userRole: 2,
                avatar: null,
                selectedAvatar: Math.floor(Math.random() * 9),
            },
            allCompanies: [],
            allUserRoles: [],
            actualStep: 1,
            showPass: false,
            userId: localStorage.getItem('userId'),
            avatarRules: [
                value => !value || value.size < 2000000 || 'Die Größe des Avatars sollte weniger als 2 MB betragen!',
            ],
            required: [
                v => !!v || 'Dies ist ein Pflichtfeld',
            ],
            emailRules: [
                v => !!v || 'Dies ist ein Pflichtfeld',
                v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'falsche E-Mail-Adresse angegeben'
            ],
        }
    },
    methods: {
        fetchAllExistingCompanies() {
            axios.get(`/company`)
                .then((response) => {
                    this.allCompanies = response.data;
                })
                .catch((e) => {
                    console.error(e);
                    this.$toast.error('...');
                })
        },
        fetchAllExistingUserRoles() {
            axios.get(`/user_role`)
                .then((response) => {
                    this.allUserRoles = response.data;
                })
                .catch((e) => {
                    console.error(e);
                    this.$toast.error('...');
                })
        },
        async registration() {
            this.loading = true;
            const formData = new FormData();
            formData.append('file', this.userData.avatar);
            formData.append('data', JSON.stringify(this.userData));
            axios.post('/registration', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                .then(() => {
                    this.$toast.success('🎉 Du hast dich erfolgreich registriert');
                    this.login();
                })
                .catch((e) => {
                    this.$toast.success('😣 Es ist ein Fehler aufgetreten. Überprüfe, ob du bereits ein Konto hast');
                    console.error(e);
                })
            this.loading = false;
        },
        async login() {
            this.loading = true;
            try {
                await this.$store.dispatch('login', {
                    username: this.userData.email,
                    password: this.userData.password
                })
                await this.$router.push('/')
            } catch (err) {
                console.error(err);
                this.error = err.detail;
                this.$toast.error('Anmeldung fehlgeschlagen');
            }
            this.loading = false;
        },
        isTokenLocalStorage() {
            if (localStorage.getItem('token') !== null) {
                axios.get(`/user/${this.userId}`)
                    .then(() => {
                        this.$router.push('/');
                    })
                    .catch(() => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('userId');
                        this.$toast.error('Benutzer ist nicht authentifiziert');
                    })
            }
        },
    },
    mounted() {
        this.isTokenLocalStorage();
        this.fetchAllExistingCompanies();
        this.fetchAllExistingUserRoles();
    },
}
</script>

<style lang="scss">

.auth-wrapper {
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 1.5rem;
    display: flex;
    min-height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    flex-basis: 100%;
}

// Card
.auth-card {
    display: flex;
    flex-flow: row nowrap;
    z-index: 3;

    .auth-img {
        flex: 1;
        border-top-left-radius: inherit;
        border-top-right-radius: 0 !important;
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: 0;

        // Eikona Logo
        .eikona-logo {
            bottom: 0;
            position: absolute;
        }
    }

    .card-content {
        flex: 1;

        .v-stepper__content {
            padding-right: 40px !important;
        }


        // Infos text
        .auth-text {
            .inline-input {
                width: min-content;
                min-width: 3rem;
                display: inline-flex;
                margin: .25rem;

                &.tiny-input {
                    width: 6rem;
                }
            }
        }
    }

    @media only screen and (max-width: 960px) {
        display: flex;
        flex-flow: column nowrap;
        .auth-img {
            max-height: 10rem;
        }
    }
}

// Backgrounds & details
.auth-mask-bg {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1;
}

.auth-tree, .auth-tree-3 {
    position: absolute;
}

.auth-tree {
    bottom: 0;
    right: 0;
}

.auth-tree-3 {
    bottom: 0;
    left: 0;
}

</style>
