<template>
    <div class="auth-wrapper auth-v1 bg-default">
        <div class="auth-inner">
            <v-card class="mr-auto ml-auto auth-card card-default" max-width="900px">

                <v-img
                    :lazy-src="require('../assets/backgrounds/small/hunger_background_08.jpg')"
                    :src="require('../assets/backgrounds/hunger_background_08.jpg')"
                    class="auth-img"
                >
                    <template v-slot:placeholder>
                        <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                        >
                            <v-progress-circular
                                indeterminate
                                color="grey lighten-5"
                            ></v-progress-circular>
                        </v-row>
                    </template>
                    <!--              EIKONA Logo-->
                    <v-img
                        class="eikona-logo ma-6"
                        width="100px"
                        :src="require('../assets/logo/eikona-logo_white.svg')"
                    ></v-img>

                </v-img>
                <!-- title -->
                <div class="card-content pa-6">
                    <v-card-text>
                        <p class="text-h4 font-weight-bold text--primary mb-2">
                            Welcome to EIKONA Hunger App!
                        </p>
                        <p class="mb-2">
                            <span v-if="forgotPassActive">Finde deinen Hunger Account</span>
                            <span
                                v-else>Bitte melde dich bei deinem Konto an und schon bald wird dein Hunger gestillt</span>
                        </p>
                    </v-card-text>

                    <!-- login form -->
                    <v-card-text>
                        <v-form
                            v-model="valid"
                            @submit.prevent="login()"
                        >
                            <v-text-field
                                outlined
                                id="username"
                                :readonly="forgotPassSendingComplete"
                                :rules="emailRules"
                                label="E-Mail Adresse"
                                placeholder="hunger@example.de"
                                name="username"
                                required
                                v-model="userData.username"></v-text-field>


                            <v-text-field
                                outlined
                                v-if="!forgotPassActive"
                                id="password"
                                :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                                :rules="required"
                                :type="showPass ? 'text' : 'password'"
                                label="Passwort"
                                placeholder="············"
                                name="password"
                                @click:append="showPass = !showPass"
                                required
                                v-model="userData.password">
                            </v-text-field>

                            <!-- Login Button -->
                            <v-btn
                                type="submit"
                                block
                                :class="forgotPassSendingComplete ? 'element-not-clickable' : ''"
                                :color="forgotPassSendingComplete ? 'success' : 'primary'"
                                :loading="loading"
                                :disabled="loading || !valid"
                            >
                            <span v-if="forgotPassActive && !forgotPassSendingComplete"> Suchen </span>
                            <span v-else-if="forgotPassSendingComplete"> E-Mail versendet <v-icon small>mdi-check-circle</v-icon> </span>
                            <span v-else> Login </span>
                            </v-btn>
                        </v-form>
                    </v-card-text>

                    <!-- create new account  -->
                    <v-card-text class="d-flex align-center justify-center flex-wrap mt-2">
          <span class="me-2">
            Neu auf unserer Plattform?
          </span>
                        <router-link :to="{name:'Register'}">
                            Ein Konto erstellen
                        </router-link>
                    </v-card-text>
                    <!--              Forgot pass-->
                    <v-card-text class="d-flex align-center justify-center flex-wrap pt-0 mt-0">
                        <a href="#" @click="forgotPassActive = !forgotPassActive, forgotPassSendingComplete = false">
                            <span v-if="forgotPassActive">Zurück zum Login</span>
                            <span v-else>Passwort vergessen</span>
                        </a>
                    </v-card-text>

                </div>


            </v-card>
            <!-- divider -->
            <v-card-text class="grey--text text-center mt-12">
                made with 🤍 in Volkach & Würzburg
            </v-card-text>
        </div>


        <!--    &lt;!&ndash; background triangle shape  &ndash;&gt;
            <v-img
              class="auth-mask-bg"
              :src="require('../assets/backgrounds/wave_01.svg')"
            ></v-img>

            &lt;!&ndash; tree &ndash;&gt;
            <v-img
              class="auth-tree"
              width="247"
              height="185"
              src='https://picsum.photos/200/300'
            ></v-img>

            &lt;!&ndash; tree  &ndash;&gt;
            <v-img
              class="auth-tree-3"
              width="377"
              height="289"
              src='https://picsum.photos/200/300'
            ></v-img>-->
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: 'Login',
    data() {
        return {
            valid: false,
            loading: false,
            userData: {
                username: null,
                password: null,
            },
            forgotPassActive: false,
            forgotPassSendingComplete: false,
            showPass: false,
            userId: localStorage.getItem('userId'),
            required: [
                v => !!v || 'Dies ist ein Pflichtfeld',
            ],
            emailRules: [
                v => !!v || 'Dies ist ein Pflichtfeld',
                v => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'falsche E-Mail-Adresse angegeben'
            ],
        }
    },
    methods: {
        // Login
        async login() {
            this.loading = true;
            if (this.forgotPassActive) {
                axios.post('/forgot_passwort', this.userData)
                    .then(() => {
                        this.$toast.success('Dein Konto wurde gefunden und wir haben dir einen Aktivierungscode für dein neues Passwort an deine E-Mail-Adresse gesendet.', {timeout: 18000});
                        this.forgotPassSendingComplete = true;
                    })
                    .catch((e) => {
                        console.error(e);
                        this.$toast.error('Leider konnten wir deinen Account nicht finden.');
                        this.resetData();
                    })
            } else {
                try {
                    await this.$store.dispatch('login', {
                        username: this.userData.username,
                        password: this.userData.password
                    })
                    await this.$router.push('/dashboard')
                } catch (err) {
                    console.error(err);
                    this.error = err.detail;
                    this.$toast.error('Anmeldung fehlgeschlagen');
                }
            }
            this.loading = false;
        },
        isTokenLocalStorage() {
            if (localStorage.getItem('token') !== null) {
                axios.get(`/user/${this.userId}`)
                    .then(() => {
                        this.$router.push('/');
                    })
                    .catch(() => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('userId');
                        this.$toast.error('Benutzer ist nicht authentifiziert');
                    })
            }
        },
        resetData() {
            this.userData.username = null;
            this.userData.password = null;
            this.forgotPassSendingComplete = false;
            this.loading = false;
        }
    },
    mounted() {
        this.isTokenLocalStorage();
    },
}
</script>

<style lang="scss">

.auth-wrapper {
    align-items: center;
    justify-content: center;
    overflow: hidden;
    padding: 1.5rem;
    display: flex;
    min-height: calc(var(--vh, 1vh) * 100);
    width: 100%;
    flex-basis: 100%;
}

// Card
.auth-card {
    display: flex;
    flex-flow: row nowrap;
    z-index: 3;

    .auth-img {
        flex: 1;
        border-top-left-radius: inherit;
        border-top-right-radius: 0 !important;
        border-bottom-left-radius: inherit;
        border-bottom-right-radius: 0;

        // Eikona Logo
        .eikona-logo {
            bottom: 0;
            position: absolute;
        }
    }

    .card-content {
        flex: 1;
    }

    @media only screen and (max-width: 960px) {
        display: flex;
        flex-flow: column nowrap;
        .auth-img {
            max-height: 10rem;
        }
    }
}

// Backgrounds & details
.auth-mask-bg {
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 1;
}

.auth-tree, .auth-tree-3 {
    position: absolute;
}

.auth-tree {
    bottom: 0;
    right: 0;
}

.auth-tree-3 {
    bottom: 0;
    left: 0;
}
</style>
