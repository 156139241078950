import axios from 'axios';

export default {
    login: async function (context, payload) {
        const response = await fetch(process.env.VUE_APP_BACKEND_API_URL + '/login', {
            method: 'POST',
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({
                username: payload.username,
                password: payload.password,
            })
        });
        const responseData = await response.json();

        if (!response.ok || !responseData.user.id) {
            let errorMessage = '';
            responseData.errors.forEach(function (error) {
                errorMessage += '<br>' + error.property_path + ': ' + error.message;
            });

            const error = new Error(errorMessage || 'Failed to authenticate.');

            console.log(error);
            throw error;
        }

        context.commit('setToken', {
            token: responseData.token
        });

        // get user's data from user id
        const userResponse = await axios.get(`/user/${responseData.user.id}`)
        const userData = userResponse.data;

        context.commit('setUser', {
            user: userData
        })

    },

    // SetToken
    setToken (context, token) {
      context.commit('setToken', {
          token: token
      })
    },

    // Logout
    logout (context) {
        context.commit('setToken', {
            token: null
        }),
        context.commit('setUser', {
            user: null
        })
    },

    async loadNewUserData (context) {
        const userResponse = await axios.get(`/user/${context.state.user.id}`)
        const userData = userResponse.data;

        context.commit('setUser', {
            user: userData
        })
    }
};
