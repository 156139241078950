<template>
  <div>
    <v-dialog
        @input="$emit('dialogChanged', $event)"
        :value="dialogGenerateInvoice"
        width="800"
        scrollable
    >
      <v-card class="card-default">
        <v-card-title class="text-h5 font-weight-bold">
          {{eventData.restaurant.name}} am {{ eventData.createdAt | luxon({ output: {format: "dd.MM.yyyy"} } )  }} um {{ eventData.collectTime | luxon({ output: {format: "HH:mm"} } )  }} Uhr
        </v-card-title>
        <v-card-subtitle>
          Editiere diese Bestellung bevor Bewirtungsaufwendungen generiert werden
        </v-card-subtitle>
        <v-divider></v-divider>
        <v-card-text>
          <v-form @submit.prevent="generateInvoice()">
            <div class="pt-8">
              <v-row>
                <v-col cols="12">
                  <v-card class="mx-auto card-default">
                    <v-img
                        height="200"
                        dark
                        :src="generateRestaurantCover()"
                        class="white--text d-flex align-center px-16"
                    >
                    </v-img>
                  </v-card>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field
                      v-model.number="newEventData.totalAmount"
                      outlined
                      id="total"
                      label="Summe der Bestellung"
                      name="total"
                      type="number"
                      required
                      append-outer-icon="mdi-currency-eur"
                  >
                  </v-text-field>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              elevation="0"
              @click="$emit('dialogChanged', false)"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="primary"
              type="submit"
              :disabled="loading"
              @click="generateInvoice()"
          >
            Speichern
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name : "GenerateInvoice",
  props: ['eventData', 'dialogGenerateInvoice'],
  methods: {
    generateRestaurantCover() {
      if (this.eventData) {
        if (this.eventData.restaurant.coverUrl) {
          return process.env.VUE_APP_BACKEND_API_URL + this.eventData.restaurant.coverUrl;
        }
        else {
          return process.env.VUE_APP_BACKEND_API_URL + '/assets/placeholder_cover.jpg';
        }
      }

    },
    generateInvoice() {
      this.loading = true;
      axios.put(`/event/${this.eventData.id}`, this.newEventData)
      .then(() => {
        const routeData = this.$router.resolve({
          path: `/event/${this.eventData.id}/invoice`,

        });
        window.open(routeData.href, "_blank");
        this.$emit('dialogChanged', false);

      })
      .finally(this.loading = false)


    },
    mappingInvoiceData() {
      this.newEventData.totalAmount = this.eventData.totalAmount
    }

  },
  mounted() {
    this.mappingInvoiceData();
  },
  data() {
    return {
      loading: false,
      newEventData: {
        totalAmount: 0
      }
    }
  }
}
</script>

<style scoped>

</style>