<template>
    <div v-if="restaurantData">
        <v-row>
            <v-col cols="12">
                <v-card class="mx-auto card-default">
                    <v-img
                        height="150"
                        dark

                        :src="generateCover(restaurantData)"
                        class="white--text align-end"
                    >
                    </v-img>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-card class="card-transparent">
                    <!--                <v-card-title class="text-h5 font-weight-bold">Test {{ restaurantData.name }}</v-card-title>-->
                    <v-card-title class="text-h5 font-weight-bold">{{ restaurantData.name }}</v-card-title>
                    <v-card-subtitle>

                    </v-card-subtitle>
                </v-card>
            </v-col>
            <v-col cols="12"
                   v-if="restaurantData !== null">
                <MenuList
                    :restaurant-data='restaurantData'
                    :cols-settings-md="3"
                    @selectedPosition="1"
                ></MenuList>
            </v-col>
        </v-row>
        <v-divider class="mt-6 mb-8"></v-divider>
        <v-row class="d-flex align-center">
            <v-col cols="8">
                <v-card class="card-default">
                    <NewMenuForm
                        :restaurant-data="restaurantData"
                        @createdMenu="fetchRestaurantData()"
                    >
                    </NewMenuForm>
                </v-card>
            </v-col>
            <v-col cols="1" class="d-flex flex-column align-center" style="max-height: 7rem;">
                <span class="mx-2 grey--text text-overline">oder</span>
            </v-col>
            <v-col cols="3">
                <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                        <v-card
                            class="card-default"
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-card-text class="grey--text text--lighten-2">
                                .csv-Datei hochladen
                            </v-card-text>
                        </v-card>
                    </template>
                    <span>Diese Funktion ist vorläufig deaktiviert</span>
                </v-tooltip>
            </v-col>

        </v-row>
    </div>
</template>

<script>
import axios from "axios";
import MenuList from "../../components/menu/MenuList.vue";
import NewMenuForm from "../../components/menu/NewMenuForm.vue";

export default {
    name: "RestaurantDashboard",
    components: {
        MenuList,
        NewMenuForm
    },
    methods: {
        fetchRestaurantData() {
            this.loadingRestaurantData = true;
            axios.get(`/restaurant/${this.$route.params.id}`)
                .then((response) => {
                    this.restaurantData = response.data;
                })
                .catch((e) => {
                    console.error(e);
                    this.$toast.error('...');
                })
                .finally(() => {
                    this.loadingRestaurantData = false;
                })

        },
        generateCover(restaurant) {
            if (restaurant.coverUrl) {
                return process.env.VUE_APP_BACKEND_API_URL + restaurant.coverUrl;
            } else return process.env.VUE_APP_BACKEND_API_URL + '/assets/placeholder_cover.jpg';
        },
    },

    data() {
        return {
            loadingRestaurantData: false,
            restaurantData: null,
        }
    },
    mounted() {
        return this.fetchRestaurantData();
    }
}
</script>

<style scoped>
.writing-mode-vertical-rl {
    writing-mode: vertical-rl !important;
}
</style>