<template><div>
    <v-toolbar class="bg-default" elevation="0">
        <v-app-bar-nav-icon class="d-lg-none" @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
        <v-spacer></v-spacer>
        <v-btn icon href="https://jira.eikona-server.de/browse/EIMEDP010-112">
            <v-icon>
                mdi-jira
            </v-icon>
        </v-btn>
        <v-btn icon>
            <v-icon>
                mdi-gitlab
            </v-icon>
        </v-btn>
        <v-menu offset-y right>
            <template v-slot:activator="{ on }">
                <v-avatar v-on="on" size="42" class="cursor-pointer">
                    <v-img
                        alt="User"
                        :src="avatarSrc">
                    </v-img>
                </v-avatar>
            </template>
            <v-card class="card-default">
                <v-list>
                    <v-list-item>
                        <v-list-item-avatar dark>
                            <v-avatar color="secondary">
                                <v-img
                                    alt="User"
                                    :src="avatarSrc">
                                </v-img>
                            </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content>

                            <v-list-item-title class="font-weight-bold">{{ $store.state.auth.user.firstname }} {{ $store.state.auth.user.lastname }}
                            </v-list-item-title>

                            <v-list-item-subtitle>{{ $store.state.auth.user.email }}</v-list-item-subtitle>
                        </v-list-item-content>

                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list
                        dense
                        nav
                    >
                        <v-list-item
                            v-for="item in items"
                            :key="item.title"
                            link
                            :to="{name:item.to}"
                        >
                            <v-list-item-icon>
                                <v-icon>{{ item.icon }}</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>{{ item.title }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>

                        <v-list-item
                            link
                            @click="logout()"
                        >
                            <v-list-item-icon>
                                <v-icon>mdi-logout-variant</v-icon>
                            </v-list-item-icon>

                            <v-list-item-content>
                                <v-list-item-title>Logout</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-list>
            </v-card>
        </v-menu>
    </v-toolbar>
    <v-navigation-drawer
        v-model="drawer"
        absolute
        temporary
    >
        <NavigationDrawer></NavigationDrawer>
    </v-navigation-drawer>
</div>
</template>

<script>
import NavigationDrawer from "./NavigationDrawer.vue";
export default {
    name: "AppToolbar",
    components: {NavigationDrawer},
    methods: {
        logout() {
            this.$store.dispatch('logout');
            this.$router.replace('/login');
        }
    },
    data() {
        return {
            items: [
                {title: 'Profil', icon: 'mdi-face', to:'AccountSettings'},
            ],
            right: null,
            avatarSrc: process.env.VUE_APP_BACKEND_API_URL + this.$store.state.auth.user.avatarPath,
            drawer: false,
        }
    },
}
</script>

<style scoped>

</style>