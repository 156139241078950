<template>
    <div>
        <v-list-item>
            <v-list-item-content>
                <HungerLogo></HungerLogo>
            </v-list-item-content>
        </v-list-item>

        <v-list
            dense
            nav
        >
            <v-list-item
                v-for="item in items"
                :key="item.title"
                link
                :to="{name:item.to}"
            >
                <v-list-item-icon>
                    <v-icon>{{ item.icon }}</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <!-- Divider -->
        <v-card-text class="d-flex align-center">
            <v-divider></v-divider>
            <span class="mx-2 grey--text text-overline">MARKETPLACE</span>
            <v-divider></v-divider>
        </v-card-text>
        <v-card class="card-default mx-4 mt-6">
            <div class="d-flex justify-space-between" style="gap: 1rem">
                <v-card-text style="flex: 1">
                    <p class="text-center mb-1">Create dein Angebot</p>
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn
                                elevation="0"
                                v-bind="attrs"
                                v-on="on"
                                disabled
                                small
                            > Erstellen
                            </v-btn>
                        </template>
                        <span>Diese Funktion ist vorläufig deaktiviert</span>
                    </v-tooltip>
                </v-card-text>
                <div style="flex: 1; position: relative">
                    <v-img
                        :src="require('@/assets/illustrations/scene-2-stand.svg')"
                        width="100%"
                        class="ma-5 flying-img"
                    ></v-img>
                </div>

            </div>
        </v-card>
        <!-- Admin Section-->
        <div v-if='$store.state.auth.user.roles.find(role => role === "ROLE_ADMIN")'>
            <v-card-text class="d-flex align-center">
                <v-divider></v-divider>
                <span class="mx-2 grey--text text-overline">ADMIN </span>
                <v-divider></v-divider>
            </v-card-text>
            <v-list
                dense
                nav
            >
                <v-list-item
                    v-for="item in adminItems"
                    :key="item.title"
                    link
                    :to="{name:item.to}"
                >
                    <v-list-item-icon>
                        <v-icon>{{ item.icon }}</v-icon>
                    </v-list-item-icon>

                    <v-list-item-content>
                        <v-list-item-title>{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
        </div>
    </div>
</template>

<script>
import HungerLogo from "../HungerLogo.vue";

export default {
    name: "NavigationDrawer",
    components: {HungerLogo},
    data() {
        return {
            items: [
                {title: 'Dashboard', icon: 'mdi-one-up', to: 'Dashboard'},
                {title: 'Bestellhistorie', icon: 'mdi-noodles', to: 'Order'},
                {title: 'Ranking', icon: 'mdi-trophy', to: 'Ranking'},
                {title: 'Restaurants', icon: 'mdi-home-map-marker', to: 'Restaurants'},
            ],
            adminItems: [
                {title: 'Übersicht', icon: 'mdi-ghost', to: 'Admin'},
            ],
            right: null,
        }
    },
}
</script>

<style scoped>
.flying-img {
    position: absolute;
    right: 0;
    bottom: 0;
}

</style>