<template>

    <v-app class="bg-default">
        <app-loader v-if="isLoading"></app-loader>
        <template v-else>
        <div v-if="showFullApp()">
            <v-navigation-drawer app class="bg-default no-borders">
                <NavigationDrawer></NavigationDrawer>
            </v-navigation-drawer>

            <!-- Sizes your content based upon application components -->
            <v-main>

                <!-- Provides the application the proper gutter -->
                <v-container fluid class="px-16">
                    <AppToolbar></AppToolbar>
                    <!-- If using vue-router -->
                    <router-view></router-view>
                </v-container>
            </v-main>

            <v-footer app>
                <!-- -->
            </v-footer>
        </div>
        <!--        for pages: Login, Registration-->
        <div v-else>
            <router-view></router-view>
        </div>
        </template>
    </v-app>
</template>

<script>
import NavigationDrawer from "./components/navigation/NavigationDrawer.vue";
import AppToolbar from "./components/navigation/AppToolbar.vue";
import AppLoader from "./components/AppLoader.vue";
import axios from "axios";

export default {
    name: 'App',
    components: {AppLoader, NavigationDrawer, AppToolbar},
    methods: {
        showFullApp() {
            var pathArray = window.location.href.split('/');
            var res = pathArray[pathArray.length - 1].split('?')[0];
            return !(res === 'login' || res === 'register' || res === 'activation' || res === 'newpass');
        },
        checkValidation() {
            axios.get('/check_auth')
                .finally(() => {
                    this.isLoading = false
                })
        }
    },
    mounted() {
        this.checkValidation();
    },
    data: () => ({
        isLoading: true,
    }),

};
</script>
