<template>
    <div>
        <v-dialog
            @input="$emit('dialogChanged', $event)"
            :value="dialogCreate"
            width="800"
            scrollable
        >
            <v-card class="card-default">
                <v-card-title class="text-h5 font-weight-bold">
                    Restaurant Creator
                </v-card-title>
                <v-card-subtitle>
                    Füge ein neues Restaurant hinzu
                </v-card-subtitle>
                <v-divider></v-divider>
                <v-card-text>
                    <v-form v-model="valid" @submit.prevent="createRestaurant()">

                        <div class="pt-8">
                            <v-row>
                                <v-col cols="12">
                                    <v-card class="mx-auto card-default">
                                        <v-img
                                            height="200"
                                            dark
                                            src="https://images.pexels.com/photos/1566837/pexels-photo-1566837.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260"
                                            class="white--text d-flex align-center px-16"
                                        >
                                            <v-file-input
                                                v-model="newRestaurantData.cover"
                                                :rules="imageRules"
                                                accept="image/png, image/jpeg, image/svg"
                                                outlined
                                                class="text-center glassy mx-16 px-16 py-6 shadow--lighter" style="border-radius: 8px"
                                                placeholder="Wähle ein Profilbild"
                                                prepend-icon="mdi-camera"
                                                label="Profilbild"
                                                show-size
                                                persistent-hint
                                                hint="Folgende Bilddateien sind erlaubt: jpg, png, svg. Die Bildgröße darf nicht 5MB überschreiten."
                                            ></v-file-input>
                                        </v-img>
                                    </v-card>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="newRestaurantData.name"
                                        outlined
                                        :rules="required"
                                        id="name"
                                        label="Name des Lokals"
                                        placeholder="Remy’s Ratatouille Adventure"
                                        name="name"
                                        required
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="newRestaurantData.googleApi"
                                        outlined
                                        id="googleApi"
                                        label="Google Api"
                                        placeholder="XxYXU6w6FXxYXcRpteXxYX"
                                        name="googleApi"
                                    >
                                    </v-text-field>
                                </v-col>
                                <v-col cols="12" sm="6">
                                    <v-text-field
                                        v-model="newRestaurantData.city"
                                        outlined
                                        id="city"
                                        label="Stadt"
                                        placeholder="Bielefeld"
                                        name="city"
                                        required
                                    >
                                    </v-text-field>
                                </v-col>
                            </v-row>
                        </div>
                    </v-form>
                </v-card-text>
                <v-divider></v-divider>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        elevation="0"
                        @click="$emit('dialogChanged', false)"
                    >
                        Abbrechen
                    </v-btn>
                    <v-btn
                        color="primary"
                        :disabled="loading || !valid"
                        @click="createRestaurant()"
                    >
                        Speichern
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-snackbar></v-snackbar>
    </div>
</template>

<script>
import axios from "axios";

export default {
    name: "NewRestaurant",
    props: ['event', 'dialogCreate'],
    methods: {
        async createRestaurant() {
            this.loading = true;
            const formData = new FormData();
            formData.append('file', this.newRestaurantData.cover);
            formData.append('data', JSON.stringify(this.newRestaurantData));
            axios.post('/restaurant', formData, {headers: {'Content-Type': 'multipart/form-data'}})
                .then(() => {
                    this.$toast.success('🎉 Success');
                })
                .catch((e) => {
                    console.error(e);
                    this.$toast.error('Es ist ein Fehler aufgetreten. Dein Lokal wurde nicht hinterlegt. ');
                })
                .finally(() => {
                    this.loading = false;
                    this.$emit('updateRestaurants');
                    this.$emit('dialogChanged', false);
                })
        },
    },
    data() {
        return {
            loading: false,
            valid: false,
            newRestaurantData: {
                name: null,
                googleApi: null,
                address: null,
                cover: null,
                city: null,
            },
            imageRules: [
                value => !value || value.size < 5000000 || 'Die Größe des Avatars sollte weniger als 5 MB betragen!',
            ],
            required: [
                v => !!v || 'Dies ist ein Pflichtfeld',
            ],
        }
    }
}
</script>

<style scoped>

</style>