<template>
    <v-img
        class="my-4"
        :src="require('../assets/logo/hunger_v2_color.svg')"
        height="100"
        contain
    ></v-img>
</template>

<script>
export default {
    name: "HungerLogo"
}
</script>

<style scoped>

</style>